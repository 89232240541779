const importFields = {
  availableFields: ["emailId", "firstName", "lastName", "phone", "company", "title", "repemailid"],
  acptdHeadersFirstName: ["firstname", "first name", "fname"],
  acptdHeadersEmail: ["email", "emailid", "email id", "emailaddress", "email address", "emails"],
  acptdHeadersLastName: ["lastname", "last name", "lname"],
  acptdHeadersPhone: ["phone", "phone no", "phone number", "mobile"],
  acptdHeadersCompany: ["company", "company name"],
  acptdHeadersTitle: ["title", "title name", "job title"],
  acptdHeadersRep: ["rep emailid", "rep email id", "repemailid"],
  acptdHeadersaddress1: ["address", "address1", "line1"],
  acptdHeadersaddress2: ["address2", "address2", "line2"],
  acptdHeadersCity: ["city", "city name", "cityname"],
  acptdHeadersState: ["state", "statename"],
  acptdHeadersCountry: ["country", "country name", "countryname"],
  acptdHeadersCounty: ["county", "county name", "countyname"],
  acptdHeadersZipCode: ["zip code", "zip", "pin", "pin code", "zipcode", "pincode", "code"],
  allParameters: [
    "firstname",
    "first name",
    "email",
    "emailid",
    "email id",
    "emailaddress",
    "email address",
    "lastname",
    "last name",
    "phone",
    "phone no",
    "phone number",
    "company",
    "company name",
    "title",
    "title name",
    "rep emailid",
    "rep email id",
    "repemailid"
  ]
};

const workflowStatus = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
  DELETE: "Delete",
  UNPUBLISHED: "Unpublished",
  FAILED: "Failed",
  SCHEDULED: "Scheduled"
};

const workflowHistoryStatus = {
  COMPLETED: "Completed",
  DROPPED: "Dropped",
  TERMINATED: "Terminated",
  FAILED: "Failed",
  INPROCESS: "InProcess"
};

const wfActiveInactiveProcessStatus = {
  PENDING: "Pending",
  INPROCESS: "InProcess",
  COMPLETED: "Completed",
  FAILED: "Failed"
};

//Please don't change any key or values. If you want to make changes, then in server utilities/workflows/workflowUtility.js, change them here also for workflowCategories keys.
const workflowTabsTypes = {
  "System Workflows": "systemWf",
  "Default Workflows": "defaultWf",
  "Rep Notifications": "repNotificationWfs",
  "Sequence Workflows": "salesWf",
  "Custom Workflows": "customWf",
  "Inactive Workflows": "inactivewfs"
};

const workflowTabs = {
  systemWorkflows: "System Workflows",
  defaultWorkflows: "Default Workflows",
  repNotifications: "Rep Notifications",
  salesWorkflows: "Sequence Workflows",
  customWorkflows: "Custom Workflows",
  inActiveWorkflows: "Inactive Workflows"
};

const checkoutFormStatus = {
  DRAFT: "Draft",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  REPLICATE: "Replicated",
  DELETE: "Delete"
};

// For Admin Access Control
const userRoles = {
  SUPERADMIN: "SuperAdmin",
  ADMIN: "Admin",
  USER: "User"
};

// There values should match with mongo DB values as of now
const accessControls = {
  optInForms: {
    enableDisclaimer: "Enable disclaimer section in froms"
  },
  optOuts: {
    enableOptouts: "Enable opt-outs"
  }
};

const OTHER_MERGE_TAGS = [
  {
    name: "ID",
    value: "<% CustomerID %>",
    key: "CustomerID"
  },
  {
    name: "Rep name",
    value: "<% repName %>",
    key: "repName"
  },
  {
    name: "Time trade link",
    value: "<% timeTradeLink %>",
    key: "timeTradeLink"
  },
  {
    name: "Social Media Link",
    value: "<% SocialMediaLink %>",
    key: "SocialMediaLink"
  },
  {
    name: "Campaign ID",
    value: "<% cmpid %>",
    key: "cmpid"
  },
  {
    name: "Rep Phone Number",
    value: "<% RepPhoneNumber %>",
    key: "RepPhoneNumber"
  },
  {
    name: "Rep Job Title",
    value: "<% RepJobTitle %>",
    key: "RepJobTitle"
  }
];

const ORDER_MERGE_TAGS = [
  {
    name: "Current Stage",
    value: "<% order_Stage %>",
    key: "order_Stage"
  },
  {
    name: "Previous Stage",
    value: "<% order_PreviousStage %>",
    key: "order_PreviousStage"
  }
];

const checkoutPageLayouts = [
  { display: "Multiple Tabs", value: 1 },
  { display: "Single Page", value: 2 }
];

const layouts = [
  { display: "Single Column", value: 1 },
  { display: "Two Column", value: 2 },
  { display: "Horizontal", value: 3 }
];

const checkoutFormLayouts = [
  { display: "Cards", value: "show" },
  { display: "CheckBoxes", value: "hide" }
];

const checkoutFormPlanSelection = [
  {
    display: "Single",
    value: "show"
  },
  { display: "Multiple", value: "hide" }
];
const PriceModeSelection = [
  {
    display: "Rounded",
    value: "show"
  },
  { display: "Without Rounded", value: "hide" }
];
const rateCardConfig = [
  {
    display: "Show",
    value: "show"
  },
  { display: "Hide", value: "hide" }
];
const giftCheckboxConfig = [
  {
    display: "Show",
    value: "show"
  },
  { display: "Hide", value: "hide" }
];

const getFormattedSource = (source) => {
  let formattedSource;
  switch (source) {
    case "addProfile":
      formattedSource = "Add Profile";
      break;
    case "import":
      formattedSource = "Import";
      break;
    case "crm":
      formattedSource = "CRM Contact";
      break;
    case "webhook":
      formattedSource = "Webhook Submission";
      break;
    case "conversion":
      formattedSource = "Conversion";
      break;
    case "chargeBrite":
      formattedSource = "ChargeBrite";
      break;
    default:
      formattedSource = source;
      break;
  }
  return formattedSource;
};

const resendCampaignDays = [
  { days: "2 days", value: 2 },
  { days: "3 days", value: 3 },
  { days: "4 days", value: 4 },
  { days: "5 days", value: 5 },
  { days: "6 days", value: 6 },
  { days: "7 days", value: 7 },
  { days: "8 days", value: 8 },
  { days: "9 days", value: 9 },
  { days: "10 days", value: 10 }
];

const DEBOUNCING_DELAY = 700;
const variateIndex = { 1: "A", 2: "B", 3: "C", W: "W" };
const REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP = "This option not available when only rep notifications enabled";
const DEFAULT_VIDEO_TAG = `<iframe width="560" height="315" src="PUT YOUR EMBED VIDEO URL HERE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
const BOT_URL_GUID1 = "8f9d31ab-6dee-40d4-92b1-278928001782";
const BOT_URL_GUID2 = "5a3b42cd-7faa-43e5-81c2-387839902873";
const BOT_URL_GUID3 = "6c7c30ea-5fcc-49c3-84a0-167817900671";
const BOT__URL = "https://www.mirabeltechnologies.com/link/";
const BOT_IDENTIFICATION_URL1 = BOT__URL + BOT_URL_GUID1;
const BOT_IDENTIFICATION_URL2 = BOT__URL + BOT_URL_GUID2;
const BOT_IDENTIFICATION_URL3 = BOT__URL + BOT_URL_GUID3;
const BOT_IDENTIFICATION_URLS = [BOT_IDENTIFICATION_URL1, BOT_IDENTIFICATION_URL2, BOT_IDENTIFICATION_URL3];
const BOT_URL_GUIDS = [BOT_URL_GUID1, BOT_URL_GUID2, BOT_URL_GUID3];
const TRIGGER_CATEGORY_TYPES = {
  REGULAR: "Regular",
  SALES_SEQUENCE: "SalesSequence",
  PREVIEW: "Preview",
  SUMMARY_NOTIFICATIONS: "SummaryNotifications",
  OTHERS: "Others"
};

const themeSectionType = {
  ThemeId: "themeId",
  ThemeName: "themeName",
  Heading: "Heading",
  Text: "Text",
  Button: "Button",
  Checkbox: "Checkbox",
  Card: "Card",
  Border: "Border",
  Link: "Link",
  Error: "Error",
  Image: "Image"
};

const enumAddressLookupType = {
  STREET_ADDRESS: 1,
  Zipcode: 2
};
const singlePlanSelections = [
  { display: "Skip for single plan", value: "skip" },
  { display: "Show single plan", value: "show" }
];

const frequency = [
  { id: "days", value: "Day(s)" },
  { id: "weeks", value: "Week(s)" },
  { id: "months", value: "Month(s)" }
];
const RSS_FREQUENCY = [
  { id: "days", value: "Daily" },
  { id: "weeks", value: "Weekly" },
  { id: "months", value: "Monthly" }
];

const monthDays = [
  { id: "1", value: "1" },
  { id: "2", value: "2" },
  { id: "3", value: "3" },
  { id: "4", value: "4" },
  { id: "5", value: "5" },
  { id: "6", value: "6" },
  { id: "7", value: "7" },
  { id: "8", value: "8" },
  { id: "9", value: "9" },
  { id: "10", value: "10" },
  { id: "11", value: "11" },
  { id: "12", value: "12" },
  { id: "13", value: "13" },
  { id: "14", value: "14" },
  { id: "15", value: "15" },
  { id: "16", value: "16" },
  { id: "17", value: "17" },
  { id: "18", value: "18" },
  { id: "19", value: "19" },
  { id: "20", value: "20" },
  { id: "21", value: "21" },
  { id: "22", value: "22" },
  { id: "23", value: "23" },
  { id: "24", value: "24" },
  { id: "25", value: "25" },
  { id: "26", value: "26" },
  { id: "27", value: "27" },
  { id: "28", value: "28" },
  { id: "29", value: "29" },
  { id: "30", value: "30" },
  { id: "31", value: "31" }
];

const weekdays = [
  { id: 0, value: "Sunday" },
  { id: 1, value: "Monday" },
  { id: 2, value: "Tuesday" },
  { id: 3, value: "Wednesday" },
  { id: 4, value: "Thursday" },
  { id: 5, value: "Friday" },
  { id: 6, value: "Saturday" }
];

const customisedFrequency = [
  { id: 0, value: "1st" },
  { id: 1, value: "2nd" },
  { id: 2, value: "5th" },
  { id: 3, value: "10th" },
  { id: 4, value: "15th" },
  { id: 5, value: "20th" },
  { id: 6, value: "25th" }
];

const popUpPageFrequency = [
  { id: 0, value: "1" },
  { id: 1, value: "2" },
  { id: 2, value: "3" },
  { id: 3, value: "4" },
  { id: 4, value: "5" },
  { id: 5, value: "10" }
];
const popUpPageDuration = [
  { id: 0, value: "1" },
  { id: 1, value: "2" },
  { id: 2, value: "5" },
  { id: 3, value: "10" },
  { id: 4, value: "20" },
  { id: 5, value: "30" },
  { id: 6, value: "60" }
];

const summaryNotificationSender = {
  ADDRESS: "Mirabel Technologies Inc.\n1401 E.Broward Blvd., #206\nFt Lauderdale, Florida 33301",
  NAME: "Admin",
  Email: "admin@mail.magazinemanager.info"
};

const LoadingWaitTexts = {
  START: [
    "Loading the magic ✨",
    "Sit back and relax, we're on it.",
    "Sit tight, we're making digital magic happen.",
    "Grab a snack, we're working hard behind the scenes.",
    "Our creativity engines are firing up!",
    "We're igniting the spark of imagination.",
    "Stay tuned for a surprise in the making.",
    "Envisioning the extraordinary, one pixel at a time.",
    "Creating a digital masterpiece just for you.",
    "Breathe in, breathe out, and let us work our magic.",
    "Pouring creativity into every byte."
  ],
  MID: [
    "Hang tight, we're doing the heavy lifting.",
    "The magic is in progress, please wait.",
    "We appreciate your patience while we work our magic.",
    "Pixels in motion, stay tuned.",
    "In the lab, brewing up a storm of creativity.",
    "Our digital forge is hard at work.",
    "Beneath the surface, something marvelous stirs.",
    "In the midst of pixel perfection.",
    "We're stitching together something incredible."
  ],
  END: [
    "Stay with us!",
    "Hang on, you're almost there!",
    "Just a little more patience, please!",
    "Waiting for the grand reveal? So are we!",
    "Your experience is getting a final polish.",
    "The magic is about to unfold.",
    "Hold on tight, we're racing towards perfection!",
    "Your experience is in the oven, almost ready!",
    "The finishing touches are being applied.",
    "Polishing the pixels for a flawless experience.",
    "The pixels are aligning for something special.",
    "Almost there, but remember, good things take time."
  ]
};

const lpTypes = {
  LANDING_PAGE: "Landing Page",
  POPUP_PAGE: "Pop Up Page",
  CHECKOUT_PAGE: "Checkout Page"
};

const campStatus = {
  DRAFT: "Draft",
  SENT: "Sent",
  FAILED: "Failed",
  SCHEDULED: "Scheduled",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  PUBLISH: "PUBLISH",
  UNPUBLISH: "UNPUBLISH"
};

const campType = {
  REGULAR: "Regular",
  AB: "AB",
  WORKFLOW: "Workflow",
  MAILCHIMP: "MailChimp",
  RSS_Feed: "RSS Feed",
  POLL: "Poll",
  RE_ENGMT: "Re-Engmt"
};

const COMPANY = "company";
const CRM = "crm";
const DYFORM = "DYFORM";
const DYFormStyles = { width: "80%", margin: "0% 10%" };

const tagsAsCheckBox = "tagsascheckbox";
const groupsAsCheckBox = "groupsascheckbox";
const uploadFileAsPf = "uploadFilePf";

const DEFAULT_VIEWS_IDS = {
  campaign: "9991",
  ConversionReport: "8881",
  WorkflowAnalytics: "9996"
};

const htmlContentTypes = {
  plaintext: "Plain Text",
  bee: "Designer",
  html: "HTML"
};

const RssCFreqTypes = {
  days: "Daily",
  weeks: "Weekly",
  months: "Monthly"
};

const RefColumnsForSorting = {
  RNO_SCD: "resendCampaignData.scheduledDate",
  RNO_SED: "resendCampaignData.lastProcessedOn",
  RNC_SCD: "resendToOpenButNotClicked.scheduledDate",
  RNC_SED: "resendToOpenButNotClicked.lastProcessedOn",
  TestVariant: "abCampaignData.variateName",
  RssCFreq: "rssFeedObject.selectedFrequency",
  RssArticleCount: "rssFeedObject.articleCount"
};

const ConversionReportSortRefCols = {
  CompanyProfile: 13,
  FormName: 2,
  Quality: 15,
  CompanyName: 11,
  orgSource: 16,
  Source: 3,
  Email: 1,
  TrackDate: 8,
  crmStatus: 12,
  FunnelType: 18,
  Score: 19,
  FirstName: 4,
  LastName: 5,
  PhoneNo: 6,
  Location: 20,
  ReferrerName: 24,
  Description: 25,
  Category: 26,
  PageUrl: 27
};

const DropdownDateFilters = [
  { Value: "Today", Display: "Today" },
  { Value: "Tomorrow", Display: "Tomorrow" },
  { Value: "Last Week", Display: "Last Week" },
  { Value: "This Week", Display: "This Week" },
  { Value: "Next Week", Display: "Next Week" },
  { Value: "Last Month", Display: "Last Month" },
  { Value: "This Month", Display: "This Month" },
  { Value: "Next Month", Display: "Next Month" }
];

const CAMPAIGN_ANALYTICS = "CAMPAIGN_ANALYTICS";
const WORKFLOW_ANALYTICS = "WORKFLOW_ANALYTICS";

const DownloadMultipleFiles = {
  Success_Text: "Your downloadable link has been generated successfully.",
  Click_Text: "Click here to download."
};

const mimeTypes = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  svg: "image/svg+xml",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain"
};
const showAndHideSubscriberForms = [
  { display: "Show Subscriber Form", value: "showSubscriberForm" },
  { display: "Go To Summary", value: "showPurchaseSummary" }
];

const keysToDisplayWithCommas = ["recipientsCount", "totalClicks", "uniqueClicks", "opensCount", "clicksCount", "oC"];

const optOutForms = {
  POLL_THANK_YOU_PAGE: "pollThankYouPage",
  EXPIRED_POLL_THANK_YOU_PAGE: "expiredPollThankYouPage",
  OPTOUT_FORM: "optOutform"
};

const followUpCampRecipientListOptions = [
  { key: "", value: "All" },
  { key: "opened", value: "Opened" },
  { key: "clicked", value: "Clicked" },
  { key: "nonopened", value: "Non Opened" },
  { key: "nonclicked", value: "Non Clicked" },
  { key: "openednotclicked", value: "Opened But Not Clicked" }
];
const pollFollowUpCampRecipientListOptions = [
  { key: "pollVoted", value: "Poll-Voted" },
  { key: "pollNotVoted", value: "Poll-Not Voted" }
];

const TRIGGER_TYPES = {
  AUTOMATION: "automation",
  STAGE_CHANGED: "stageChanged",
  SUMMARY_NOTIFICATION: "summaryNotification"
};

const CAMPAIGN_SUB_TYPES = {
  automateInvoice: "CreateInvoice",
  automatePrepay: "PrepayApply",
  automateBatchbilling: "BatchBilling",
  automateSalesModuleReports: "ScheduledReport_SalesModule",
  automateLateInvoiceNotices: "LateInvoiceNotice",
  scheduleInvoiceReport: "ScheduleInvoiceReport",
  schedulePrepayReport: "SchedulePrepayReport",
  scheduleBatchbillingReport: "ScheduleBatchBillingReport",
  automateRevenueAndGoalsReport: "ScheduledReport_RevenueAndGoals"
};
const subscriberformButtonTypes = {
  SUBMIT_BUTTON: "submitButton",
  NEXT_BUTTON: "nextButton",
  PREVIOUS_BUTTON: "previousButton"
};
const radioButtonborderStyles = ["None", "Solid", "Dashed", "Dotted", "Inset", "Outset"];

const DYNAMIC_FORM_SOURCE = {
  FORM_BUILDER: "FOMR_BUILDER",
  FORM_VIEWCODE: "FORM_VIEWCODE",
  FORM_PREVIEW: "FORM_PREVIEW",
  LANDING_PAGE_BUILDER: "LANDING_PAGE_BUILDER",
  LANDING_PAGE_PUBLISH: "LANDING_PAGE_PUBLISH",
  LANDING_PAGE_PREVIEW: "LANDING_PAGE_PREVIEW",
  SSP_FORM_BUILDER: "SSP_FOMR_BUILDER"
};

const DYNAMIC_FORM_ACTIONS = {
  SAVE_SECTION: "SAVE_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  FORM_SUBMISSION: "FORM_SUBMISSION"
};

const defaultButtonStylesforLP = {
  submit: {
    paddingTop: "10px",
    paddingLeft: "50px",
    paddingBottom: "10px",
    paddingRight: "50px",
    borderRadius: "5px",
    borderColor: "#fafafa",
    borderWidth: "1px",
    borderStyle: "None"
    // backgroundColor: "#3d4592",
    // fontFamily: "Helvetica"
  },
  nextButton: {
    paddingTop: "10px",
    paddingLeft: "50px",
    paddingBottom: "10px",
    paddingRight: "50px",
    borderRadius: "5px",
    borderColor: "#fafafa",
    borderWidth: "1px",
    borderStyle: "None"
    // backgroundColor: "#3d4592",
    // fontFamily: "Helvetica"
  },
  previousButton: {
    paddingTop: "10px",
    paddingLeft: "40px",
    paddingBottom: "10px",
    paddingRight: "40px",
    borderRadius: "5px",
    borderColor: "#fafafa",
    borderWidth: "1px",
    borderStyle: "None"
    // backgroundColor: "#ff4f00",
    // fontFamily: "Helvetica"
  }
};

const PREVIEW_TEMPLATE_SOURCE = {
  DYNAMIC_FORM: "DYNAMIC_FORM",
  SSP_FORM_BUILDER: "SSP_FORM_BUILDER"
};

const DATE_SORT_VALUES = { lastUpdatedDate: "Modified Date", createdDate: "Created Date" };

const TRIGGER_CATEGORY = {
  PRODUCTION: "production",
  CHARGEBRITE: "chargeBrite"
};
const openAIPrompts = [
  {
    Id: 1278,
    PageName: "",
    Prompt: "Correct Grammar",
    Promptquery: "Correct the grammar based on the below text",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1307,
    PageName: "",
    Prompt: "Convert to Bullet Points",
    Promptquery: "Convert the below text into bullet points",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1336,
    PageName: "",
    Prompt: "5 Versions of Similar Text",
    Promptquery: "Generate 5 versions of the below text",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1365,
    PageName: "",
    Prompt: "Make More Sympathetic",
    Promptquery: "Make the below text more sympathetic and apologetic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1394,
    PageName: "",
    Prompt: "Make Text Better",
    Promptquery: "Make the below text better",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1423,
    PageName: "",
    Prompt: "Make Text Longer",
    Promptquery: "Make the below text longer",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1452,
    PageName: "",
    Prompt: "Make More Diplomatic",
    Promptquery: "Make the below text more diplomatic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1481,
    PageName: "",
    Prompt: "Make More Enthusiastic",
    Promptquery: "Make the below text more enthusiastic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },

  {
    Id: 1510,
    PageName: "",
    Prompt: "Make More Optimistic",
    Promptquery: "Make the below text more optimistic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1511,
    PageName: "",
    Prompt: "Make Text Shorter",
    Promptquery: "Make the below text shorter",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  }
];

const pollThankYouPageType = {
  GO_TO_POLL_SUMMARY_REPORT: "goToPollSummary"
};

const tagSubtagsVisitedOptions = [
  {
    value: false,
    label: "Show All"
  },
  {
    value: true,
    label: "Show visited only"
  }
];

const settingType = {
  AUTO_POPULATE: "autoPopulate",
  WARMUP: "warmup"
};

const openAISubjectPrompts = [
  {
    Id: 1336,
    PageName: "",
    Prompt: "5 Versions of Similar Text",
    Promptquery: "Generate 5 versions of the below text",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1278,
    PageName: "",
    Prompt: "Correct Grammar",
    Promptquery: "Correct the grammar based on the below text",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },

  {
    Id: 1365,
    PageName: "",
    Prompt: "Make More Sympathetic",
    Promptquery: "Make the below text more sympathetic and apologetic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },
  {
    Id: 1394,
    PageName: "",
    Prompt: "Make Text Better",
    Promptquery: "Make the below text better",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  },

  {
    Id: 1481,
    PageName: "",
    Prompt: "Make More Enthusiastic",
    Promptquery: "Make the below text more enthusiastic",
    TemplatePrompt: null,
    Category: "",
    ParentId: null,
    Temperature: 1,
    Icon: null,
    Styles: null
  }
]; // will remove after changes are finalised

const PROFILE_FIELD_TYPES = {
  RADIO: "radio",
  DROPDOWN: "dropdown",
  NUMBER: "number"
};

const SELECTED_RECIPIENT_TYPE = {
  SEGMENT: 0,
  GROUPS: 1,
  TAGS: 2,
  ALL_PROFILES: 3
};
const variateMap = {
  1: "variateA",
  2: "variateB",
  W: "variateW"
};
const abVariates = ["A", "B"];
const DO_NOT_EMAIL = "donotEmail";

const FILE_COPY_STATUSES = ["CopiedToDestination", "DupFileCopiedToDestination"];

const FILE_EXISTS_STATUS = ["FileExistsInDestination"];

const entityTypes = { folder: "folder", file: "file" };

const domainTypes = {
  1: "suppressed",
  2: "public"
};

const dataUsageReportDateFilters = [
  { Value: "Yearly", Display: "Yearly" },
  { Value: "Quarterly", Display: "Quarterly" },
  { Value: "Monthly", Display: "Monthly" }
];

const enrollmentDashboardSources = {
  SYNC_HISTORY: "syncHistory",
  PENDING_SYNC_HISTORY: "pendingSyncHistory",
  CRM_CDC_SYNC_HISTORY: "crmCdcSyncHistory",
  CRM_CDC_SYNC_PENDING_HISTORY: "crmCdcSyncPendingHistory"
};
const LpNavigationTypes = {
  LANDING_PAGE: "landingpages",
  POPUP_PAGE: "popUpPage",
  CHECKOUT_PAGE: "checkoutPage",
  HTML_UPLOAD: "htmlUpload"
};

const campaignActionTypes = {
  SENT: "Sent",
  SCHEDULED: "Scheduled",
  SEND_TEST: "sendtest",
  PUBLISHED: "Published"
};

const MOBILE_VIEW_MAX_WIDTH = 426;

const keysToRemoveFromUsageReportHeader = [
  "ChildModuleId",
  "ChildModuleName",
  "ParentModuleId",
  "ParentModuleName",
  "key",
  "Column1",
  "Column2",
  "Column3",
  "Column4",
  "SortOrder",
  "ParentSortOrder"
];
const defaultDataReportFilterObj = { Value: "0", Display: "All" };

const zeroFilterDropdownData = [
  { Value: "0", Display: "Show All Features" },
  { Value: "1", Display: "Features Adopted" },
  { Value: "2", Display: "Features Not Adopted" }
];

const PAGINATION = {
  PER_PAGE_10: 10,
  PER_PAGE_20: 20,
  PER_PAGE_30: 30
};

const LANDING_PAGE_TYPES = {
  LANDING_PAGE: "LP",
  POPUP_PAGE: "PP",
  CHECKOUT_PAGE: "CP",
  SVLP: "SVLP"
};

const SELECT_WRAPPER_SOURCES = ["terminateManual", "dataUsageReport"];

const FORM_TYPES = {
  SSP_FORM_BUILDER: "sspForm",
  EMBEDDED_FORM: "embeddedForm"
};

const DEFAULT_GROUP_IDS = {
  CRMCONTACT: "5c46659defaf0c32d165b973",
  WEBSITE_ACTIVITY: "5c46659defaf0c32d165b975",
  MEDIA_KIT_GROUP: "65ded07f553b59b47ecd2b02",
  NEWSLETTER_A: "65e15a123af09af5c0892d03"
};

const NAVIGATION_PATHS = {
  BUILDER_FORMS: "/builderForms/sspForm" // for self service portal builder forms
};

const SiteTypes = {
  MM: "MM",
  CRM: "CRM",
  STANDALONECRM: "STANDALONECRM"
};

const SiteTypeActions = {
  ISALLOWED: "isAllowed",
  LIMITCHECK: "limitCheck"
};

const moduleNames = {
  CAMPAIGNS: "Campaigns",
  CAMPAIGNS_SENT: "SentCampaigns",
  CAMPAIGNS_PUBLISHED: "PublishedCampaigns",
  FORMS: "Forms",
  LANDINGPAGES: "LandingPages",
  LANDINGPAGE_DOMAINS: "LandingPageDomains",
  WOKRFLOWS: "Workflows",
  SENDERS: "Senders",
  PROFILES: "Contacts",
  INCOMING_WEBHOOK: "IncomingWebhook",
  OUTGOING_WEBHOOK: "OutgoingWebhook"
};
const APIRESPONSE_STATUS = {
  OVERLIMIT: "OverLimit",
  UNDERLIMIT: "UnderLimit"
};
const LIMIT_EXCEEDS_MSG = "Limit Exceeds";
//NEED to move the cnames in .env file
const CNAMES = [
  { id: 1, recordType: "CNAME", key: "pmtakey120._domainkey", value: "pmtakey120._domainkey.mail.mirabelmail.com" },
  // {
  //   id: 2,
  //   recordType: "CNAME",
  //   key: "scph0324._domainkey",
  //   value: "pmtakey220._domainkey.{filteredDomain}.mirabelmail.com"
  // },
  { id: 3, recordType: "CNAME", key: "pmtakey320._domainkey", value: "pmtakey320._domainkey.mail.mirabelmail.com" },
  { id: 4, recordType: "CNAME", key: "mailmt", value: "mail.mirabelmail.com" },
  { id: 5, recordType: "CNAME", key: "mailsp", value: "mailsp.mirabelmail.com" },
  { id: 6, recordType: "TXT", key: "@", value: "Validity-Domain-Verification=Z+STTbvvLcYaP5dj4JDDL5AXzv4=" }
];

const CAMPAIGN_LIST_VIEW = "CAMPAIGN_LIST_VIEW";
const CONVERSION_REPORT = "CONVERSION_REPORT";
const OPPORTUNITY = "Opportunity";
const VALID_PROTOCOLS = [
  "http://",
  "https://",
  "mailto:",
  "tel:",
  "sms:",
  "ftp://",
  "file://",
  "geo:",
  "whatsapp:",
  "skype:",
  "viber:",
  "intent:",
  "market:",
  "<%"
];

const formThemeData = {
  title: [
    {
      title: "Heading1",
      style: {
        fontFamily: "Calibri",
        fontSize: "18px",
        textAlign: "Center",
        color: "#5b60f1",
        fontWeight: "Bold",
        marginBottom: "8px"
      }
    },
    {
      title: "Heading2",
      style: {
        fontFamily: "Calibri",
        fontSize: "17px",
        textAlign: "Center",
        color: "#5c5757",
        fontWeight: "Bolder",
        marginBottom: "10px"
      }
    },
    {
      title: "Heading3",
      style: {
        fontFamily: "Arial",
        fontSize: "18px",
        textAlign: "Center",
        color: "#5DB4FE",
        fontWeight: "Bold",
        marginBottom: "10px"
      }
    },
    {
      title: "Heading4",
      style: {
        fontFamily: "Verdana",
        fontSize: "18px",
        textAlign: "Center",
        color: "#003466",
        fontWeight: "Bold",
        marginBottom: "12px"
      }
    },
    {
      title: "Custom",
      style: {}
    }
  ],
  titleDescription: [
    {
      title: "Heading1",
      style: {
        fontFamily: "Calibri",
        fontSize: "14px",
        textAlign: "Left",
        color: "#404040",
        marginBottom: "16px"
      }
    },
    {
      title: "Heading2",
      style: {
        fontFamily: "Calibri",
        fontSize: "17px",
        textAlign: "Center",
        color: "#404040",
        marginBottom: "10px"
      }
    },
    {
      title: "Heading3",
      style: {
        fontFamily: "Arial",
        fontSize: "12px",
        textAlign: "Left",
        color: "#000000",
        marginBottom: "16px"
      }
    },
    {
      title: "Heading4",
      style: {
        fontFamily: "Verdana",
        fontSize: "12px",
        textAlign: "Left",
        color: "#404040",
        marginBottom: "12px"
      }
    },
    {
      title: "Custom",
      style: {}
    }
  ],
  label: [
    {
      title: "Heading1",
      style: {
        fontFamily: "Calibri",
        fontSize: "16px",
        lineHeight: "1.5",
        color: "#404040",
        textAlign: "Left"
      }
    },
    {
      title: "Heading2",
      style: {
        fontFamily: "Calibri",
        fontSize: "16px",
        lineHeight: "2",
        color: "#404040",
        textAlign: "Left"
      }
    },
    {
      title: "Heading3",
      style: {
        fontFamily: "Arial",
        fontSize: "16px",
        lineHeight: "2",
        color: "#000000",
        textAlign: "Center"
      }
    },
    {
      title: "Heading4",
      style: {
        fontFamily: "Verdana",
        fontSize: "16px",
        lineHeight: "1.5",
        color: "#404040",
        textAlign: "Center"
      }
    },
    {
      title: "Custom",
      style: {}
    }
  ],
  field: [
    {
      title: "Heading1",
      style: {
        fontFamily: "Calibri",
        fontSize: "16px",
        color: "#646060",
        marginBottom: "4px",
        padding: "4px",
        backgroundColor: "#FFFFF",
        borderStyle: "Solid",
        borderWidth: "1px",
        borderColor: "#a9a9a9"
      }
    },
    {
      title: "Heading2",
      style: {
        fontFamily: "Calibri",
        fontSize: "16px",
        color: "#000000",
        marginBottom: "4px",
        padding: "4px",
        backgroundColor: "#FFFFF",
        borderStyle: "Dashed",
        borderWidth: "1px",
        borderColor: "#a9a9a9"
      }
    },
    {
      title: "Heading3",
      style: {
        fontFamily: "Arial",
        fontSize: "16px",
        color: "#404040",
        marginBottom: "4px",
        padding: "4px",
        backgroundColor: "#f3f2f2",
        borderStyle: "Dotted",
        borderWidth: "2px",
        borderColor: "#a9a9a9"
      }
    },
    {
      title: "Heading4",
      style: {
        fontFamily: "Verdana",
        fontSize: "16px",
        color: "#404040",
        marginBottom: "4px",
        padding: "4px",
        backgroundColor: "#f3f2f2",
        borderStyle: "Dashed",
        borderWidth: "1px",
        borderColor: "#dedede"
      }
    },
    {
      title: "Custom",
      style: {}
    }
  ]
};

const formFontWeights = {
  BOLD: "Bold",
  NORMAL: "Normal"
};

const CAMPAIGNTYPES = {
  RE_ENGMT: "Re-Engmt",
  WORKFLOW: "Workflow",
  RSS_FEED: "RSS_Feed"
};

const STANDARD_PROFILE_FIELDS = {
  CURRENT_DATE: "todaysDate"
};
const RESEND_DELIVERY_STATUS = {
  NOT_SCHEDULED: 0,
  SCHEDULED: 1,
  DELIVERED: 2,
  FAILED: 3
};
const COMMON_FORM_STYLES = ["fontFamily", "fontSize", "color"];

const FORM_STYLE_ATTRIBUTES = {
  title: ["fontFamily", "fontSize", "textAlign", "color", "fontWeight", "marginBottom"],
  titleDescription: ["fontFamily", "fontSize", "textAlign", "color", "marginBottom"],
  label: ["fontFamily", "fontSize", "lineHeight", "color", "textAlign"],
  field: [
    "fontFamily",
    "fontSize",
    "color",
    "marginBottom",
    "padding",
    "backgroundColor",
    "borderStyle",
    "borderWidth",
    "borderColor"
  ],
  checkboxes: ["fontFamily", "fontSize", "color", "accentColor", "whiteSpace"],
  radioButton: ["fontFamily", "fontSize", "color", "backgroundColor", "whiteSpace"],
  radioButtonConvertedToButton: [
    "fontFamily",
    "fontSize",
    "color",
    "backgroundColor",
    "borderRadius",
    "padding",
    "width",
    "borderStyle",
    "borderWidth",
    "borderColor",
    "ActiveButtonColor"
  ],
  tagsAsCheckBoxes: ["fontFamily", "fontSize", "color", "accentColor", "whiteSpace"],
  groupsAsCheckBoxes: ["fontFamily", "fontSize", "color", "accentColor", "whiteSpace"],
  disclaimer: ["fontFamily", "fontSize", "color", "marginBottom"],
  submitButton: [
    "btnText",
    "fontFamily",
    "fontSize",
    "color",
    "backgroundColor",
    "textAlign",
    "hoverColor",
    "borderStyle",
    "borderWidth",
    "borderColor",
    "buttonPadding",
    "borderRadius"
  ],
  nextButton: [
    "nextBtnText",
    "fontFamily",
    "fontSize",
    "color",
    "backgroundColor",
    "textAlign",
    "hoverColor",
    "borderStyle",
    "borderWidth",
    "borderColor",
    "buttonPadding",
    "borderRadius"
  ],
  previousButton: [
    "previousBtnText",
    "fontFamily",
    "fontSize",
    "color",
    "backgroundColor",
    "textAlign",
    "hoverColor",
    "borderStyle",
    "borderWidth",
    "borderColor",
    "buttonPadding",
    "borderRadius"
  ],
  required: ["color"],
  errorMsg: ["fontWeight", "color"],
  helpText: ["color"],
  customText: ["fontFamily", "fontSize", "color"]
};

const formAttributeKeys = {
  TITLE: "title",
  TITLE_DESCRIPTION: "titleDescription",
  LABEL: "label",
  FIELD: "field",
  CHECKBOXES: "checkboxes",
  RADIO_BUTTON: "radioButton",
  RADIO_BUTTON_AS_BUTTON: "radioButtonConvertedToButton",
  TAGS_AS_CHECKBOXES: "tagsAsCheckBoxes",
  GROUPS_AS_CHECKBOXES: "groupsAsCheckBoxes",
  DISCLAIMER: "disclaimer",
  SUBMIT_BUTTON: "submitButton",
  NEXT_BUTTON: "nextButton",
  PREVIOUS_BUTTON: "previousButton",
  REQUIRED: "required",
  ERROR_MSG: "errorMsg",
  HELP_TEXT: "helpText",
  CUSTOM_TEXT: "customText"
};

const ANALYTICS_TRACKING_MODULES = {
  CAMPAIGN_LIST_VIEW: "campaignListView",
  WORKFLOW_ANALYTICS: "workflow-analytics",
  CONVERSION_REPORT: "conversionReport",
  AI_SUBJECT: "aiInSubject",
  AI_EMAIL_CONTENT: "aiInEmailContent",
  AI_PLAIN_TEXT_EDITOR: "aiInPlainTextEditor",
  CAMPAIGN_PERFORMANCE_REPORT: "campaignPerformanceReport"
};

const ADVANCED_SEARCH_COMPONENTS = {
  MULTI_SELECT_DROPDOWN: "MultiSelectDropDown",
  AUTO_COMPLETE_TEXT_BOX: "AutoCompleteTextBox",
  DATE_COMPONENT: "DateComponent",
  WF_TRIGGER_MULTI_SELECT_DROPDOWN: "WfTriggerMultiSelectDropDown"
};

const BUTTON_ELEMENTS = ["submitButton", "nextButton", "previousButton"];
const RADIO_BUTTON_ELEMENTS = ["radioButton", "radioButtonConvertedToButton"];

const BUTTON_BG_COLOR_LABEL = "Button Color";
const RADIO_BUTTON_BG_COLOR_LABEL = "Radio Button Color";
const AI_PROMPT_PLACEHOLDER_TEXT = "Type your own prompt for our AI…";
const HEADER_FOR_SINGLE_CONVERSION_PAGE = "Media Order Checkout Form Responses";

const SELECTED_SEGMENT_SELECTALL = -9999;

const PLAIN_TEXT_EDITOR_EMPTY_CONTENTS_ARRAY = [
  `<p>Type your text here (or) Copy the text into the notepad &amp; then paste it into the editor, copy-pasting directly from other sources may result in formatting issues...</p>\n`,
  `<p></p>\n`,
  `<p><br></p>\n`
];
const CAMPAIGN_PERFORMANCE_REPORT = "CAMPAIGN_PERFORMANCE_REPORT";

const FORM_CATEGORIES = "formCategories";
const FORM_TAGS = "formTags";

const FORM_CATEGORIES_CONFIG = {
  url: "/api/formCategory",
  method: "POST",
  body: { action: "get" },
  type: FORM_CATEGORIES
};
const FORM_TAGS_CONFIG = {
  url: "/api/formTags",
  method: "POST",
  body: { action: "get" },
  type: FORM_TAGS
};

const PanelTypes = {
  MOVE_FILE_PANEL: "moveFilePanel",
  MOVE_TEMPLATE_PANEL: "moveTemplatePanel",
  SAVE_TEMPLATE_PANEL: "saveTemplatePanel"
};

const FOLDER_TYPES = {
  CAMPAIGN_DESIGNER: "campDesigner",
  CAMPAIGN_HTML: "campHtml",
  CAMPAIGN_PLAIN_TEXT: "campPlainText",
  CAMPAIGN_RSS_FEED: "campRSS",
  CAMPAIGN_POLL: "campPoll",
  LP_HTML: "lpHtml",
  LP_DESIGNER: "lpDesigner",
  LP_POPUP: "lpPopups",
  LP_CHECKOUT_PAGE: "lpCheckoutPages"
};

const TEMPLATE_FOLDER_KEYS = {
  FOLDER_TREE: "folderTree",
  FOLDER_NAME: "folderName"
};

const HOME_FOLDER_NAME = "Home";

module.exports = {
  DEBOUNCING_DELAY,
  frequency,
  monthDays,
  weekdays,
  customisedFrequency,
  RSS_FREQUENCY,
  popUpPageFrequency,
  popUpPageDuration,
  importFields,
  workflowStatus,
  workflowTabs,
  workflowTabsTypes,
  workflowHistoryStatus,
  userRoles,
  accessControls,
  OTHER_MERGE_TAGS,
  ORDER_MERGE_TAGS,
  getFormattedSource,
  checkoutFormStatus,
  layouts,
  checkoutPageLayouts,
  resendCampaignDays,
  variateIndex,
  REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP,
  DEFAULT_VIDEO_TAG,
  BOT_IDENTIFICATION_URL1,
  BOT_IDENTIFICATION_URL2,
  BOT_IDENTIFICATION_URL3,
  TRIGGER_CATEGORY_TYPES,
  themeSectionType,
  enumAddressLookupType,
  BOT_URL_GUID1,
  BOT_URL_GUID2,
  BOT_URL_GUID3,
  singlePlanSelections,
  summaryNotificationSender,
  LoadingWaitTexts,
  lpTypes,
  campStatus,
  campType,
  tagsAsCheckBox,
  groupsAsCheckBox,
  uploadFileAsPf,
  DEFAULT_VIEWS_IDS,
  htmlContentTypes,
  RssCFreqTypes,
  RefColumnsForSorting,
  CAMPAIGN_ANALYTICS,
  WORKFLOW_ANALYTICS,
  COMPANY,
  CRM,
  DYFORM,
  DYFormStyles,
  DownloadMultipleFiles,
  mimeTypes,
  wfActiveInactiveProcessStatus,
  showAndHideSubscriberForms,
  keysToDisplayWithCommas,
  optOutForms,
  checkoutFormLayouts,
  checkoutFormPlanSelection,
  followUpCampRecipientListOptions,
  pollFollowUpCampRecipientListOptions,
  TRIGGER_TYPES,
  CAMPAIGN_SUB_TYPES,
  subscriberformButtonTypes,
  DYNAMIC_FORM_SOURCE,
  radioButtonborderStyles,
  defaultButtonStylesforLP,
  PREVIEW_TEMPLATE_SOURCE,
  DATE_SORT_VALUES,
  TRIGGER_CATEGORY,
  DYNAMIC_FORM_ACTIONS,
  openAIPrompts,
  openAISubjectPrompts,
  PROFILE_FIELD_TYPES,
  pollThankYouPageType,
  tagSubtagsVisitedOptions,
  settingType,
  SELECTED_RECIPIENT_TYPE,
  variateMap,
  abVariates,
  DO_NOT_EMAIL,
  FILE_COPY_STATUSES,
  FILE_EXISTS_STATUS,
  entityTypes,
  domainTypes,
  ConversionReportSortRefCols,
  DropdownDateFilters,
  dataUsageReportDateFilters,
  enrollmentDashboardSources,
  LpNavigationTypes,
  campaignActionTypes,
  keysToRemoveFromUsageReportHeader,
  defaultDataReportFilterObj,
  MOBILE_VIEW_MAX_WIDTH,
  zeroFilterDropdownData,
  PAGINATION,
  LANDING_PAGE_TYPES,
  SELECT_WRAPPER_SOURCES,
  FORM_TYPES,
  DEFAULT_GROUP_IDS,
  NAVIGATION_PATHS,
  SiteTypes,
  SiteTypeActions,
  moduleNames,
  APIRESPONSE_STATUS,
  CNAMES,
  LIMIT_EXCEEDS_MSG,
  CAMPAIGN_LIST_VIEW,
  CONVERSION_REPORT,
  OPPORTUNITY,
  VALID_PROTOCOLS,
  formThemeData,
  formFontWeights,
  rateCardConfig,
  CAMPAIGNTYPES,
  giftCheckboxConfig,
  STANDARD_PROFILE_FIELDS,
  RESEND_DELIVERY_STATUS,
  COMMON_FORM_STYLES,
  FORM_STYLE_ATTRIBUTES,
  formAttributeKeys,
  ANALYTICS_TRACKING_MODULES,
  ADVANCED_SEARCH_COMPONENTS,
  BUTTON_ELEMENTS,
  RADIO_BUTTON_ELEMENTS,
  BUTTON_BG_COLOR_LABEL,
  RADIO_BUTTON_BG_COLOR_LABEL,
  BOT_IDENTIFICATION_URLS,
  BOT_URL_GUIDS,
  AI_PROMPT_PLACEHOLDER_TEXT,
  HEADER_FOR_SINGLE_CONVERSION_PAGE,
  SELECTED_SEGMENT_SELECTALL,
  PLAIN_TEXT_EDITOR_EMPTY_CONTENTS_ARRAY,
  CAMPAIGN_PERFORMANCE_REPORT,
  PriceModeSelection,
  FORM_CATEGORIES,
  FORM_TAGS,
  FORM_CATEGORIES_CONFIG,
  FORM_TAGS_CONFIG,
  PanelTypes,
  FOLDER_TYPES,
  TEMPLATE_FOLDER_KEYS,
  HOME_FOLDER_NAME
};
