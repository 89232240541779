import moment from "moment";
import CryptoJS from "crypto-js";
import { apiCall } from "./apiCall";
import {
  CAMPAIGN_SUB_TYPES,
  STANDARD_PROFILE_FIELDS,
  ANALYTICS_TRACKING_MODULES,
  mimeTypes,
  ADVANCED_SEARCH_COMPONENTS,
  BOT_URL_GUIDS,
  TEMPLATE_FOLDER_KEYS,
  HOME_FOLDER_NAME
} from "./enums";
import axios from "axios";
import {
  AUTOMATE_SCHEDULED_REPORTS_MERGE_TAG,
  REP_NOTIFICATION_MERGE_TAGS,
  WORKFLOW_MERGE_TAGS,
  SUBSCRIPTION_MERGE_TAGS,
  ADMATERIAL_MERGE_TAGS,
  AD_APPROVAL_MERGE_TAGS,
  LATENOTICE_MERGE_TAGS,
  PROD_CONTACT_MERGE_TAGS,
  SUMMARY_NOTIFICATION_MERGE_TAGS,
  PROD_NOTIFICATION_MERGE_TAGS,
  RSS_FEED_MERGETAGS,
  INVOICE_SUMMARY_MERGE_TAG,
  PREPAY_SUMMARY_MERGE_TAG,
  BATCH_BILLING_SUMMARY_MERGE_TAG,
  AUTOMATE_LATE_INVOICE_NOTICES_MERGE_TAGS,
  SCHEDULE_REPORT_MERGE_TAGS
} from "./configs/bee.config";
import { showAlert } from "./alertmessage";
export const formatDate = (date) => moment(date).format("MMM D YYYY, h:mm A");
export const formatDateYYYMMDD = (date) => moment(date).format("YYYY-MM-DD");
export const setZeroTimeToDate = (date) => moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
export const clientSpecificDate = (date) =>
  moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes").format("MMM D YYYY, h:mm A");
export const clientSpecificDateOnly = (date) =>
  moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes").format("MMM D, YYYY");

export const clientSpecificDateWithSkipDays = (date, skipDays = false) => {
  let adjustedDate = moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes");
  adjustedDate = skipDays ? skipWeekends(adjustedDate) : adjustedDate;
  return adjustedDate.format("MMM D dddd YYYY, h:mm A");
};

export const skipWeekends = (date) => {
  let adjustedDate = moment.utc(date);
  const dayOfWeek = adjustedDate.day();
  if (dayOfWeek === 6) {
    adjustedDate = adjustedDate.add(2, "days");
  } else if (dayOfWeek === 0) {
    adjustedDate = adjustedDate.add(1, "day");
  }
  return adjustedDate;
};

export const getUTCDate = (date) => moment.utc(date).format("MMM D YYYY, h:mm A");
export const getFrequencyDateRange = (frequencyType) => {
  const now = new Date();
  const toDaydate = now.getDate();
  let fromDate, toDate;
  if (frequencyType === "t") {
    fromDate = now;
    toDate = now;
  } else if (frequencyType === "y") {
    fromDate = new Date(moment().subtract(1, "days"));
    toDate = new Date(moment().subtract(1, "days"));
  } else if (frequencyType === "tw") {
    const dayOfNow = now.getDay();
    fromDate = new Date(moment().subtract(dayOfNow, "days"));
    toDate = now;
  } else if (frequencyType === "lw") {
    const dayOfNow = now.getDay();
    fromDate = new Date(moment().subtract(dayOfNow + 7, "days"));
    toDate = new Date(moment().subtract(dayOfNow + 1, "days"));
  } else if (frequencyType === "tm") {
    fromDate = new Date(moment().subtract(toDaydate - 1, "days"));
    toDate = now;
  } else if (frequencyType === "lm") {
    fromDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    toDate = new Date(moment().subtract(toDaydate, "days"));
  } else if (frequencyType == "currentYear") {
    fromDate = moment().startOf("year").format("YYYY-MM-DD");
    toDate = moment().endOf("year").format("YYYY-MM-DD");
  } else if (frequencyType == "nextYear") {
    fromDate = moment().add(1, "year").startOf("year").format("YYYY-MM-DD");
    toDate = moment().add(1, "year").endOf("year").format("YYYY-MM-DD");
  } else if (frequencyType == "pastYear") {
    fromDate = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
    toDate = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
  }
  return { fromDate, toDate };
};
const ENV_PRODUCTION = "production";
const MM_DOMAIN_URL = "magazinemanager";
const MKM_DOMAIN_URL = "mirabelsmarketingmanager";
const NM_DOMAIN_URL = "newspapermanager";
export const abWinnerSendDate = (date) =>
  moment.utc(date).add(parseInt(window.timeZoneMinutes), "minutes").format("DD-MMM-YYYY, h:mm A");
let _mmDomain, _mkmDomain, _nmDomain;
if (process.env.REACT_APP_ENV === ENV_PRODUCTION) {
  _mmDomain = "https://mrktapp.magazinemanager.com/";
  _mkmDomain = "https://app.mirabelsmarketingmanager.com/";
  _nmDomain = "https://mrktapp.newspapermanager.com/";
} else if (process.env.REACT_APP_ENV === "staging") {
  _mmDomain = "https://stgmrktapp.magazinemanager.biz/";
  _mkmDomain = "https://stgmrktapp.mirabelsmarketingmanager.biz/";
  _nmDomain = "https://stgmrktapp.newspapermanager.biz/";
} else if (process.env.REACT_APP_ENV === "smoke") {
  _mmDomain = "https://t1mrktapp.magazinemanager.com/";
  _mkmDomain = "https://t1mrktapp.mirabelsmarketingmanager.com/";
  _nmDomain = "https://t1mrktapp.newspapermanager.com/";
} else {
  _mmDomain = "https://t1mrktapp2.magazinemanager.com/";
  _mkmDomain = "https://t1mrktapp2.mirabelsmarketingmanager.com/";
  _nmDomain = "https://t1mrktapp2.newspapermanager.com/";
}
export const mmDomain = _mmDomain;
export const mkmDomain = _mkmDomain;
let _EmailServiceAppUrl = "",
  _AccountAuthURL = "",
  _PortalURL = "",
  _AMAZON_S3_IMAGE_URL = "https://mmclientfilespublic-dev.s3.amazonaws.com/";

const prod_Staging_Sites = ["production", "staging"];

if (prod_Staging_Sites.includes(process.env.REACT_APP_ENV)) {
  _AMAZON_S3_IMAGE_URL = "https://mmclientfilespublic.s3.amazonaws.com/";
}
if (process.env.REACT_APP_ENV === "production") {
  _EmailServiceAppUrl = "https://emailservice.mirabelsmarketingmanager.com/";
  _AccountAuthURL = "https://accounts-auth.mirabeltechnologies.com/";
  _PortalURL = "https://portal.mirabeltechnologies.com/";
} else if (process.env.REACT_APP_ENV === "staging") {
  _EmailServiceAppUrl = "https://stgemailservice.magazinemanager.biz/";
  _AccountAuthURL = "https://accounts-authstaging.mirabeltechnologies.com/";
  _PortalURL = "https://portal.mirabeltechnologies.de/";
} else if (process.env.REACT_APP_ENV === "smoke") {
  _EmailServiceAppUrl = "https://t1emailservice.magazinemanager.com/";
  _AccountAuthURL = "http://accounts-authtier1.mirabeltechnologies.com/";
  _PortalURL = "http://tier1-portal2.mirabeltechnologies.com/";
} else {
  _EmailServiceAppUrl = "https://t1emailservice2.magazinemanager.com/";
  // _EmailServiceAppUrl = `http://localhost:8088`;
  _AccountAuthURL = "https://accounts-authdev.mirabeltechnologies.com/";
  _PortalURL = "https://mirabeldev-portal.mirabeltechnologies.com/";
}

export const EmailServiceAppUrl = _EmailServiceAppUrl;
export const CPAccountAuthURL = _AccountAuthURL;
export const CPAccountSettingsURL = `${_AccountAuthURL}intranet/Members/Account/AccountSettings.aspx?product=MSCP`;
export const CustomerPortalURL = _PortalURL;
export const AMAZON_S3_IMAGE_URL = _AMAZON_S3_IMAGE_URL;
export const CAMPAIGN_TYPE_LIMIT = 20;
export const CAMPAIGN_CONTENT_DEFAULT_TEXT = "lorem ipsum dolor sit amet";
export const TEST_CAMPAIGN_RECIPIENT_SELECT_LIMIT = 10;
export const TEST_CAMPAIGN_RECIPIENT_LIST_LIMIT = 100;
export const EXPORT_COUNT_LIMIT = 200000;
export const numberWithCommas = (number) =>
  isNaN(number) ? 0 : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const getDomainUrl = () => {
  let mkmInteractionsURL = _mmDomain;
  const isMKM = window.location.hostname.includes("mirabelsmarketingmanager");
  const isNM = window.location.hostname.includes("newspapermanager");
  if (isMKM && _mkmDomain) {
    mkmInteractionsURL = _mkmDomain;
  } else if (isNM && _nmDomain) {
    mkmInteractionsURL = _nmDomain;
  }
  return mkmInteractionsURL;
};
export const getStepCount = (workflowStats, stepId) => {
  let profileCount = 0;
  if (workflowStats && workflowStats.length > 0) {
    for (const stat of workflowStats) {
      if (stat.stepId == stepId) {
        profileCount = stat.count;
        break;
      }
    }
  }
  return profileCount;
};

export const getActiveEnrollmentCount = (workflowStats, id) => {
  let count = getStepCount(workflowStats, id);
  return {
    count,
    placeholder: count >= 100 ? "99+" : count,
    showTitle: count >= 100 ? true : false
  };
};

export const getTimezoneString = (timeZoneMinutes, actualTimeZone) => {
  let timeZone = window["actualTimeZone"] ? window["actualTimeZone"] : actualTimeZone;
  return timeZoneMinutes == 0
    ? "GMT + 00:00"
    : timeZoneMinutes < 0
    ? `GMT - ${moment.utc().startOf("day").add(Math.abs(timeZoneMinutes), "minutes").format("hh:mm")} (${timeZone})`
    : `GMT + ${moment.utc().startOf("day").add(timeZoneMinutes, "minutes").format("hh:mm")} (${timeZone})`;
};

// prettier-ignore
export const getHyphenatedURLPath = (url) =>
  url
    .replace(/^\//, "") // Omit forward slash (`/`) in the beginning
    .replace(/[^a-zA-Z 0-9-_]+/g, "")
    .trim() // Remove spaces from both ends
    .replace(/\s+/g, "-") // Replace all the between spaces with hyphens
    .toLowerCase(); // Convert the path to lowercase

export const toProperCase = (text) =>
  text
    ? text.replace(/\w\S*/g, function (text) {
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
      })
    : "";

export const receiver = (e) => {
  let postData = e.data;
  if (typeof postData == "object") {
    const action = postData.Action;
    if (action === "WebsiteChange") {
      const obj = {
        websiteID: postData.Data.WebsiteID
      };
      return apiCall("/api/UpdateCurrentWebsiteID", "POST", obj);
    } else if (action == "Logout") {
      sessionStorage.removeItem("ACCESS_CONTROL");
      apiCall("/api/logout");
    } else if (postData.type === "ADD_OPPORTUNITY") {
      return true;
    } else if (action === "MKM_Page_Reload") {
      return postData.Data;
    }
  }
};

export const postMessageToAllTabs = (postMessageObj) => {
  window.parent.postMessage(postMessageObj, "*");
};

export const dateRangeOptions = {
  t: "Today",
  y: "Yesterday",
  tw: "This Week",
  lw: "Last Week",
  tm: "This Month",
  lm: "Last Month",
  between: "Between"
};
export const numberRangeOptions = {
  e: "Equal to",
  lt: "Less than",
  gt: "Greater than",
  lte: "Less than equal to",
  gte: "Greater than equal to",
  between: "Between",
  ne: "Not equal to"
};
export const contractDateRangeOptions = {
  expiry: "Expiring / Expired",
  current_future: "Current or Future",
  any_order: "Any Orders",
  no_order: "No Orders"
};
export const subContractDateRangeOptions = {
  on: "On",
  after: "After",
  before: "Before",
  between: "Between",
  in_next: "In next",
  in_last: "In last"
  // "in_next_days": "in next __ days",
  // "in_next_weeks": "in next __ weeks",
  // "in_next_months": "in next __ months",
  // "in_last_days": "in last __ days",
  // "in_last_weeks": "in last __ weeks",
  // "in_last_months": "in last __ months"
};
export const daysWeeksMonthsOptions = {
  days: "Days",
  weeks: "Weeks",
  months: "Months"
};

export const dateRangeFilterOptions = {
  before: "Before",
  after: "After"
};

export const daysWeeksOptions = {
  days: "Days",
  weeks: "Weeks"
};

export const dateProperties = [
  {
    display: "Order Expiration Date",
    value: "oexpDt"
  },
  {
    display: "Order Start Date (Ad Material)",
    value: "startDate"
  },
  {
    display: "Order Created",
    value: "dateAdded"
  },
  {
    display: "Ad Material Deadline Date",
    value: "DeadlineDate"
  },
  {
    display: "Late Notice",
    value: "DueDate"
  },
  {
    display: "Project Start Date",
    value: "ProjectStartDate"
  },
  {
    display: "Project Completed Date",
    value: "ProjectEndDate"
  },
  {
    display: "Due Date",
    value: "OrderDueDate"
  },
  {
    display: "Mailing List Close Date",
    value: "MailingListCloseDate"
  },
  {
    display: "Space Reservation Deadline",
    value: "SpaceReservationDeadline"
  },
  {
    display: "Files Shipped To Printer",
    value: "FilesShippedToPrinter"
  },
  {
    display: "Proofs Arrive From Printer",
    value: "ProofsArriveFromPrinter"
  },
  {
    display: "Periodicals Shipped From Printer",
    value: "PeriodicalsShippedFromPrinter"
  },
  {
    display: "Accounting Close Date",
    value: "AccountingCloseDate"
  },
  {
    display: "On Sale Date",
    value: "OnSaleDate"
  }
];

export const OrdersProductionProperties = [
  {
    display: "Assigned To",
    value: "AssignedTo"
  },
  {
    display: "Priority",
    value: "Priority"
  },
  {
    display: "Designer Change",
    value: "Designer"
  },
  {
    display: "File Upload",
    value: "FileName"
  },
  {
    display: "Ad Pickup",
    value: "PickupContractID"
  },
  {
    display: "Requires Design",
    value: "RequiresDesign"
  },
  {
    display: "Production Note Added",
    value: "ProductionNote"
  }
];
export const subscriptionDateProperties = [
  {
    display: "Subscription Created Date",
    value: "CreatedOn"
  },
  {
    display: "Subscription Start Date",
    value: "StartDate"
  },
  {
    display: "Subscription End Date",
    value: "SubscriptionEndDate"
  },
  {
    display: "Subscription Billing Date",
    value: "NextBillingAt"
  },
  {
    display: "Subscription Trial Start Date",
    value: "TrialStartDate"
  },
  {
    display: "Subscription Trial End Date",
    value: "TrialEndDate"
  },
  {
    display: "Subscription Renewal Date",
    value: "RenewalDate"
  },
  {
    display: "Subscription Cancelled Date",
    value: "ToBeCancelled"
  },
  {
    display: "Subscription Pause Date",
    value: "ToBePaused"
  },
  {
    display: "Subscription Resume Date",
    value: "ToBeResumedOn"
  }
];

export const subscriptionDateFields = {
  StartDate: "Subscription Start Date",
  SubscriptionEndDate: "Subscription End Date",
  NextBillingAt: "Subscription Billing Date",
  TrialStartDate: "Subscription Trial Start Date",
  TrialEndDate: "Subscription Trial End Date",
  RenewalDate: "Subscription Renewal Date",
  ToBeCancelled: "Subscription Cancelled Date",
  ToBePaused: "Subscription Pause Date",
  ToBeResumedOn: "Subscription Resume Date",
  startDate: "Order Start Date",
  oexpDt: "Order Expiration Date",
  CreatedOn: "Subscription Created Date"
};

export const statusProperties = [
  {
    display: "Renewed",
    value: "Renewed"
  },
  {
    display: "Cancelled",
    value: "Cancelled"
  },
  {
    display: "Paused",
    value: "Pause"
  },
  {
    display: "Resumed",
    value: "Resume"
  }
];
export const subscriptionCancellationStatusProperties = [
  // {
  //   display: "Initiated",
  //   value: "Initiated"
  // },
  {
    display: "Removed",
    value: "Removed"
  }
];
export const displayDaysWeeksMonths = {
  days: "day(s)",
  weeks: "week(s)"
};

export const getContractDateRange = (frequencyType) => {
  const now = new Date();
  let fromDate = now,
    toDate = now;

  if (frequencyType === "cf") {
    fromDate = now;
  } else if (frequencyType === "ep") {
    fromDate = new Date(moment().subtract(1, "days"));
  }
  return { fromDate, toDate };
};

export const decimalWithCommas = function (number = 0) {
  return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
export const decimalWithCommasZeroPrecision = function (number = 0) {
  return number.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

const getFormattedAmountWithPriceMode = (priceMode, amount, currencySymbol) => {
  if (priceMode === "number" || priceMode === "roundedNumber") {
    if (amount < 0) {
      return `(${currencySymbol || ""}${decimalWithCommas(Math.abs(amount))})`;
    } else {
      return `${
        !(priceMode === "number") && amount % 1 === 0
          ? decimalWithCommasZeroPrecision(amount)
          : decimalWithCommas(amount)
      }`;
    }
  }
  if (priceMode === "currency" || priceMode === "roundedCurrency") {
    if (amount < 0) {
      return `(${currencySymbol || ""}${decimalWithCommas(Math.abs(amount))})`;
    } else {
      return `${currencySymbol || ""}${
        !(priceMode === "currency") && amount % 1 === 0
          ? decimalWithCommasZeroPrecision(amount)
          : decimalWithCommas(amount)
      }`;
    }
  }
};

export const formatAmountWithCurrency = function (amount, currencySymbol, priceMode = null) {
  if (amount === "") {
    return "";
  }
  if (priceMode) return getFormattedAmountWithPriceMode(priceMode, amount, currencySymbol);
  return amount < 0
    ? `(${currencySymbol || ""}${decimalWithCommas(Math.abs(amount))})`
    : `${currencySymbol || ""}${decimalWithCommas(amount)}`;
};

export const formatAmountWithCurrencyInSummaryPage = function (
  amount,
  currencySymbol,
  totalSubscribers = 1,
  isCostDetailsEnabled,
  priceMode = null
) {
  if (amount === "") {
    return "";
  }
  // required for totalSubscribers > 1 case, ex: $150 ($50 x 3)
  const actualAmtStr = `${currencySymbol || ""}${decimalWithCommas(amount)}`;
  const subscribersAmtText =
    totalSubscribers > 1 && isCostDetailsEnabled ? ` (${actualAmtStr} x ${totalSubscribers})` : "";

  const totalAmount = amount * totalSubscribers;

  if (priceMode) return getFormattedAmountWithPriceMode(priceMode, totalAmount, currencySymbol);

  return totalAmount < 0
    ? `(${currencySymbol || ""}${decimalWithCommas(Math.abs(totalAmount))})${subscribersAmtText}`
    : `${currencySymbol || ""}${decimalWithCommas(totalAmount)}${subscribersAmtText}`;
};

export const crmEncryptDecryptKey = "$%x@#~$a";
export const authEncryptDecryptKey = "20SA16ED";
export const AUTO_SAVE_RELOAD_MILLI_SECONDS = 15000;
export const ACTIVE_USERS_CHECK_MILLI_SECONDS = 10000;
export const MAX_UPLOADED_FILES = 5;

export const encryptByDES = (message, key) => {
  let keyHex = CryptoJS.enc.Utf8.parse(key);
  let ivHex = CryptoJS.enc.Utf8.parse(key);
  keyHex = CryptoJS.SHA1(keyHex);
  ivHex = CryptoJS.SHA1(ivHex);
  // CryptoJS use CBC as the default mode, and Pkcs7 as the default padding scheme
  var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.CBC,
    iv: ivHex,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
};
export const getESTDate = (date, flag) => {
  let offset = window.estDateOffset;
  if (flag == "heading") {
    return moment.utc(date).add(offset, "minutes").format("MMM D YYYY");
  } else {
    return moment.utc(date).add(offset, "minutes").format("MMM D YYYY, h:mm A");
  }
};

export const isFieldEmpty = (field) => {
  if (field === "" || field === null || field === undefined || (Array.isArray(field) && field.length == 0)) {
    return true;
  }
  return false;
};

export const getTextFromHTML = function (htmlContent) {
  let a = document.createElement("span");
  a.innerHTML = htmlContent;
  return a.textContent;
};
export const checkEmailMasked = function (email) {
  return email.includes("*");
};
export const restrictTwoDecimalPlaces = function (number) {
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};
// function from https://stackoverflow.com/a/5624139/3695983
function hexToRgb(hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

// function from https://stackoverflow.com/a/9733420/3695983
function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export const calculateContrastRatio = function (color1, color2) {
  const color1rgb = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);

  // calculate the relative luminance
  const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b);
  const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b);

  // calculate the color contrast ratio
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05);

  return ratio;
};

export const repColorCodes = [
  "#469FBB",
  "#FF5733",
  "#C81549",
  "#0B831C",
  "#A8B41C",
  "#0C475A",
  "#900C3F",
  "#27AE60",
  "#f80c9c",
  "#9064DF"
];

export const getShortName = (name) => {
  let shortName = "";
  if (name) {
    const arrayNames = name.split(" ");
    if (arrayNames.length >= 2) {
      shortName = arrayNames[0].substr(0, 1) + arrayNames[1].substr(0, 1);
    } else if (arrayNames.length == 1) {
      shortName = arrayNames[0].substr(0, 1);
    }
  }
  return shortName.toUpperCase();
};
export const groupBy = (data, key) => {
  return data.reduce((acc, x) => {
    acc[x[key]] = [...(acc[x[key]] || []), x];
    return acc;
  }, {});
};
export const API_TIMEOUT = 5 * 60 * 1000;
export const MODAL_TIMEOUT_SECONDS = 30;

export const extractCampaignContentLinks = (htmlContent) => {
  let campaignContentLinks = [];
  let hrefs = [];
  let divDoc = document.createElement("div");
  divDoc.innerHTML = htmlContent;
  const html = divDoc.innerHTML;
  const anchors = divDoc.querySelectorAll("a");
  Array.from(anchors).forEach(function (anchor) {
    let { href } = anchor;
    if (href) {
      // For manually created Links , Line number 222 will automatically add slash at the end which is not part of html Content , so removing slash for those links(this is not only the reason for removal apart from this enrollments not happening because of the check)
      if (!html.includes(href) && href.charAt(href.length - 1) == "/") {
        href = href.slice(0, -1);
      }
      href = href.replace("https://www.", "").replace("http://www.", "").replace("https://", "").replace("http://", "");

      const queryStringStartIndex = href.indexOf("?");
      if (queryStringStartIndex > -1) href = href.substring(0, queryStringStartIndex);
      if (hrefs.indexOf(href) < 0 && !BOT_URL_GUIDS.some((guid) => href.includes(guid))) {
        campaignContentLinks.push({ value: decodeURI(href), display: decodeURI(href) });
        hrefs.push(href);
      }
    }
  });
  //if atleast one link is there we need to add "any" option
  if (campaignContentLinks.length > 0) {
    campaignContentLinks.splice(0, 0, { value: "any", display: "Any" });
  }
  return campaignContentLinks;
};

export const commonIframeRender = (_iFrame, html) => {
  if (_iFrame && _iFrame.current) {
    _iFrame.current.contentWindow.document.open();
    _iFrame.current.contentWindow.document.write("");
    _iFrame.current.contentWindow.document.close();
    _iFrame.current.contentWindow.document.write(html);
  }
  return _iFrame;
};
export const filesAndFolderSort = (_items, searchTerm, sortColumn, sortOrder, _) => {
  let children;
  if (searchTerm) children = _items.children.filter((item) => item.name.toLowerCase().includes(searchTerm));
  else children = _items.children;

  let items = _.mapValues(_.groupBy(children || [], "type"), (v) =>
    _.orderBy(v, [(item) => (sortColumn == "name" ? item.name.toLowerCase() : item.size)], [sortOrder])
  );
  if (sortColumn == "dateCreated") {
    const sortedFiles = _.orderBy(
      items.file,
      (object) => {
        return new Date(object.dateCreated);
      },
      [sortOrder]
    );
    if (sortedFiles.length > 0) {
      items.file = sortedFiles;
    }
  }
  return items;
};

export const generateThumbnail = (generateTempThumbnail, templateId, source) => {
  if (generateTempThumbnail) {
    const data = {
      templateId,
      generateTempThumbnail,
      source
    };
    apiCall("/api/generateThumbnail", "POST", data).then((groups) => {
      //
    });
  }
};
export const getRepIDs = async (url) => {
  const resp = await apiCall(`/api/${url}`, "GET");
  const repUserList = resp.repsList.map((item) => {
    let repValue = "";
    if (item.firstName && item.lastName && item.firstName.length > 0 && item.lastName.length > 0) {
      repValue = item.firstName + " " + item.lastName;
    } else if (item.firstName && item.firstName.length > 0 && (item.lastName === undefined || item.lastName === "")) {
      repValue = item.firstName;
    } else if (item.email && item.email.length > 0) {
      repValue = item.email.indexOf("@") > -1 ? item.email.substring(0, item.indexOf("@")) : item.email;
    } else if (
      (item.firstName === undefined || item.firstName == "") &&
      (item.lastName === undefined || item.lastName == "") &&
      (item.email === undefined || item.email == "")
    ) {
      repValue = "InsufficientDetails";
    }
    if (repValue !== "") {
      return { repId: item.repUserId, repValue };
    } else {
      return { repId: item.repUserId, repValue: "No reps found" };
    }
  });
  return repUserList;
};
export const getRepNameById = (repId, repList) => {
  let repName = "";
  if (repId == undefined) return "No Rep";
  const repItem = repList.find((item) => item.repId == repId);
  if (repItem !== undefined) {
    if (repItem.repValue && repItem.repValue !== "") {
      repName = repItem.repValue;
    } else {
      repName = "N/A";
    }
  } else {
    repName = "Rep not found";
  }
  return repName;
};

export const getRepNameFromRepDetails = (repDetails) => {
  let repName = "No Rep";
  if (repDetails) {
    if (
      repDetails.firstName &&
      repDetails.lastName &&
      repDetails.firstName.length > 0 &&
      repDetails.lastName.length > 0
    ) {
      repName = repDetails.firstName + " " + repDetails.lastName;
    } else if (
      repDetails.firstName &&
      repDetails.firstName.length > 0 &&
      (repDetails.lastName === undefined || repDetails.lastName === "")
    ) {
      repName = repDetails.firstName;
    } else if (repDetails.email && repDetails.email.length > 0) {
      repName =
        repDetails.email.indexOf("@") > -1 ? repDetails.email.substring(0, repDetails.indexOf("@")) : repDetails.email;
    } else if (
      (repDetails.firstName === undefined || repDetails.firstName == "") &&
      (repDetails.lastName === undefined || repDetails.lastName == "") &&
      (repDetails.email === undefined || repDetails.email == "")
    ) {
      repName = "InsufficientDetails";
    }
  }
  return repName;
};

export const headerAssociatedCampaigns = async (
  settingId,
  headerName,
  PrilfeConfirmationAlert,
  showConfirm,
  promptType,
  message
) => {
  const apiResponse = await apiCall(`/api/checkHeaderAssociatedCampaigns?id=${settingId}`, "GET");

  let listMessage = [],
    draftCampaigns = [],
    publishedCampaigns = [],
    unPublishedCampaigns = [];
  if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
    apiResponse.associatedCampaigns.forEach((camp) => {
      if (camp.Status == "Draft") draftCampaigns.push(camp.CampaignName);
      if (camp.Status == "Published") {
        publishedCampaigns.push(camp.CampaignName);
      }
      if (camp.Status == "Unpublished") {
        unPublishedCampaigns.push(camp.CampaignName);
      }
    });
    if (draftCampaigns.length > 0) listMessage.push({ title: "Draft", list: draftCampaigns });
    if (publishedCampaigns.length > 0) listMessage.push({ title: "Published", list: publishedCampaigns });
    if (unPublishedCampaigns.length > 0) listMessage.push({ title: "Unpublished", list: unPublishedCampaigns });
    //Show Confirmation Box
    let confirmBox = PrilfeConfirmationAlert.deleteImpactAreasConfirm;
    confirmBox.title = promptType;
    confirmBox.msgText = message;
    const showDeleteHeader = await showConfirm({ ...confirmBox, list: listMessage });
    if (showDeleteHeader == false) {
      return false;
    }
  }
  return true;
};

export const footerAssociatedCampaigns = async (
  settingId,
  PrilfeConfirmationAlert,
  showConfirm,
  promptType,
  message
) => {
  const apiResponse = await apiCall(`/api/checkFooterAssociatedCampaigns?id=${settingId}`, "GET");
  if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
    let listMessage = [],
      draftCampaigns = [],
      publishedCampaigns = [],
      unPublishedCampaigns = [],
      scheduledCampaigns = [];
    if (apiResponse.associatedCampaigns && apiResponse.associatedCampaigns.length > 0) {
      apiResponse.associatedCampaigns.forEach((camp) => {
        if (camp.Status == "Draft") draftCampaigns.push(camp.CampaignName);
        else if (camp.Status == "Published") {
          publishedCampaigns.push(camp.CampaignName);
        } else if (camp.Status == "Unpublished") {
          unPublishedCampaigns.push(camp.CampaignName);
        } else scheduledCampaigns.push(camp.CampaignName);
      });
      if (draftCampaigns.length > 0) listMessage.push({ title: "Draft", list: draftCampaigns });
      if (scheduledCampaigns.length > 0) listMessage.push({ title: "Scheduled", list: scheduledCampaigns });
      if (publishedCampaigns.length > 0) listMessage.push({ title: "Published", list: publishedCampaigns });
      if (unPublishedCampaigns.length > 0) listMessage.push({ title: "Unpublished", list: unPublishedCampaigns });
    }

    //Show Confirmation Box
    let confirmBox = PrilfeConfirmationAlert.deleteImpactAreasConfirm;
    confirmBox.title = promptType;
    confirmBox.msgText = message;
    const deleteFooter = await showConfirm({ ...confirmBox, list: listMessage });
    if (deleteFooter == false) {
      return false;
    }
  }
  return true;
};

export const dynamicRSSMergeTags = async (rssFeedUrl) => {
  const response = await apiCall(`/api/getRSSXML?rssFeedUrl=${rssFeedUrl}`, "GET");
  let dynamicRSSTags = [];
  if (response && response.status == "success") {
    if (response.date && response.date.status == "success") {
      const rssItemObj = response.date && response.date.data.channel[0].item;
      let itemKeys = [];
      for (var i = 0; i < rssItemObj.length; i++) {
        Object.keys(rssItemObj[i]).forEach(function (key) {
          if (itemKeys.indexOf(key) == -1) {
            itemKeys.push(key);
          }
        });
      }
      let RSSTags = itemKeys.filter((obj1) => !RSS_FEED_MERGETAGS.some((obj2) => obj2.key === `RSS_${obj1}`));
      dynamicRSSTags = RSSTags.map((tag) => {
        return (tag = {
          name: `RSS ${tag.charAt(0).toUpperCase() + tag.slice(1)}`,
          value: `<% RSS_${tag} %>`,
          key: `RSS_${tag}`
        });
      });
    } else {
      return "error";
    }
  }
  return dynamicRSSTags;
};
export function handleProfileDataFormat(fieldsList, header, value) {
  let formattedValue = "";
  let isSubscriptionDateField = ["Subscription Start Date", "Subscription End Date"].includes(header);
  let headerNames = fieldsList.map((item) => item.displayName);
  if (headerNames.includes(header) || isSubscriptionDateField) {
    let type = isSubscriptionDateField == false ? fieldsList.find((item) => item.displayName == header).fieldType : "";
    if (type == "date" && value) {
      formattedValue = moment(new Date(value)).format("DD/MM/YYYY");
    } else if (type == "datetime-local" && value) {
      formattedValue = moment(new Date(value)).format("DD/MM/YYYY HH:mm");
    } else if (isSubscriptionDateField) {
      formattedValue = clientSpecificDate(value);
    } else {
      formattedValue = Array.isArray(value) ? value.join(", ") : value;
    }
  } else {
    formattedValue = Array.isArray(value) ? value.join(", ") : value;
  }
  return formattedValue;
}

export function rssFieldValidation(
  rssFeedUrl,
  selectedFrequency,
  articleCount,
  selectedTime,
  selectedMonthDay,
  selectedWeekDay,
  readMoreText,
  readMoreCharLimit,
  readMoreMT
) {
  let rssSaveEnable = false;
  if (!rssFeedUrl || !selectedFrequency || !articleCount || !selectedTime) {
    rssSaveEnable = false;
  } else {
    if (selectedFrequency !== "days" && !selectedMonthDay && !selectedWeekDay) {
      rssSaveEnable = false;
    } else {
      if (readMoreText && (!readMoreCharLimit || !readMoreMT)) {
        rssSaveEnable = false;
      } else if (readMoreCharLimit && (!readMoreText || !readMoreMT)) {
        rssSaveEnable = false;
      } else if (readMoreMT && (!readMoreText || !readMoreCharLimit)) {
        rssSaveEnable = false;
      } else {
        rssSaveEnable = true;
      }
    }
  }
  return rssSaveEnable;
}

export const popupPageAssociations = async (lpId, ConfirmationAlert, showConfirm, setIsLoading) => {
  let url = `/api/ppAssociatedLps?isPopUpPage=${true}`;
  const associatedResponse = await apiCall(url, "POST", {
    lpId: lpId
  });
  if (associatedResponse && associatedResponse.ppAssociatedLps && associatedResponse.ppAssociatedLps.length > 0) {
    let landingPages = [];
    associatedResponse.ppAssociatedLps.map((lp) => {
      if (lp.status == "Published") {
        landingPages.push(lp.lpName);
      }
    });
    if (landingPages.length > 0) {
      let confirmBox = ConfirmationAlert.republishLp;
      confirmBox.title = "Unpublish Pop Up Page";
      confirmBox.msgText = "To unpublish the pop-up page, please unlink all the associated landing pages listed below.";
      setIsLoading(false);
      await showConfirm({ ...confirmBox, data: landingPages });
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const tagsAsCheckBoxEnabled = (layout, fields) => {
  let isTagsAsCheckBoxEnabled =
    layout == 2 &&
    fields &&
    fields.length > 0 &&
    fields.some((field) => ["tagsAsCheckbox", "groupsAsCheckbox"].includes(field.type) && field.isChecked === true);
  return isTagsAsCheckBoxEnabled;
};

export const getESAppUrl = () => {
  return `${window.location.origin}/`;
  let esAppUrl = "";
  if (process.env.REACT_APP_ENV === ENV_PRODUCTION) {
    esAppUrl = MM_DOMAIN_URL;

    if (window.location.hostname.includes(MKM_DOMAIN_URL)) esAppUrl = MKM_DOMAIN_URL;
    else if (window.location.hostname.includes(NM_DOMAIN_URL)) esAppUrl = NM_DOMAIN_URL;
    esAppUrl = `https://emailservice.${esAppUrl}.com/`;
  } else {
    esAppUrl = _EmailServiceAppUrl;
  }
  return esAppUrl;
};
export const openESPageinMMTab = async (pageUrl, pageTitle) => {
  let mmPostDate = { pgUrl: getESAppUrl() + pageUrl, pgTitle: pageTitle };
  window.parent.postMessage(mmPostDate, "*");
};

//open contact page when user click on CRM Icon
export const openContactPage = (gsCustomersId, addParams) => {
  let contactPageUrl = "/ui/contactedit?gsCustomersID=" + gsCustomersId;
  if (addParams) {
    contactPageUrl += addParams;
  }
  let mmPostDate = { pgUrl: contactPageUrl };
  window.parent.postMessage(mmPostDate, "*");
};

export const generateURLWithPayload = (URL, myObject) => {
  const encodedPayload = encodeURIComponent(encodeURIComponent(JSON.stringify(myObject)));
  return `${URL}?mrkt_cPayload=` + encodedPayload;
};

export const extractPayloadFromURL = (URL) => {
  const queryParams = new URLSearchParams(URL);
  const payload = queryParams.get("mrkt_cPayload");
  try {
    if (!payload) return URL;
    const decodedPayload = decodeURIComponent(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload;
  } catch (error) {
    console.error("Error while decoding query params of new campaign payload --> ", error.message);
    return null;
  }
};

export const closeESPageinMMTab = () => {
  window.parent.postMessage("closeActTab", "*");
};

export function generateMappingArray(wtIds, wstIds) {
  return [
    ...wtIds.filter((id) => id !== 0).map((id) => ({ WebsiteTagID: id, WebsiteSubtagID: 0 })),
    ...wstIds.filter((id) => id !== 0).map((id) => ({ WebsiteTagID: 0, WebsiteSubtagID: id }))
  ];
}
export const thumbnailGenOnView = (imageRef, setIsThumbnailLoaded) => {
  if (imageRef && imageRef.current) {
    const options = {
      root: null, //Use Page as root
      rootMargin: "0px",
      threshold: 0
    };
    const observer = new IntersectionObserver((entities) => {
      if (entities[0].isIntersecting) {
        setIsThumbnailLoaded(true);
      }
    }, options);
    if (observer) {
      observer.observe(imageRef.current);
    }
  }
};

export const disableSubmitButton = (html, enable = false) => {
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(html, "text/html");
  const targetElements = parsedDocument.querySelectorAll('[id^="mkm-es-subscribe"]');
  if (targetElements.length > 0) {
    targetElements.forEach((element) => {
      if (enable) {
        element.removeAttribute("disabled");
      } else {
        element.setAttribute("disabled", "true");
      }
    });
  }
  return parsedDocument.documentElement.outerHTML;
};
export const getTotalFilePathCount = (selectedProfiles) => {
  let totalFilePathCount = 0;
  selectedProfiles.forEach((profile) => {
    if (profile.filePath) {
      if (Array.isArray(profile.filePath)) {
        totalFilePathCount += profile.filePath.length;
      } else {
        totalFilePathCount++;
      }
    }
  });
  return totalFilePathCount;
};

export const downloadSingleFile = async (filePath, fileName) => {
  let response = await axios.post(
    "/api/downloadBase64",
    { filePath, fileName },
    {
      responseType: "string"
    }
  );
  function extractExtension(fileName) {
    const extensionParts = fileName.split(".");
    const fileExtension = extensionParts.pop();
    return fileExtension.toLowerCase();
  }
  let ext = extractExtension(fileName);

  if (response.status === 200) {
    const dataUri = `data:${mimeTypes[ext]};base64,${response.data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = fileName;
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    return true;
  } else {
    console.error("Request failed with status: " + response.status);
    return false;
  }
};

export const displayWarningIcon = (disabledSender, status, level = 0) => {
  return level == 0 && disabledSender && ["Draft", "Published", "Unpublished", "Scheduled"].includes(status);
};

export const getAutomateMergeTags = ({
  MERGE_TAGS,
  isSN,
  campaignSubType,
  isNotificationMergeTags,
  ORDER_FIELDS_MERGE_TAGS,
  isAllowedForClient
}) => {
  let mergeTags = [];
  const automationMergeTagsMapping = {
    [CAMPAIGN_SUB_TYPES.automateInvoice]: INVOICE_SUMMARY_MERGE_TAG,
    [CAMPAIGN_SUB_TYPES.automatePrepay]: PREPAY_SUMMARY_MERGE_TAG,
    [CAMPAIGN_SUB_TYPES.automateBatchbilling]: BATCH_BILLING_SUMMARY_MERGE_TAG,
    [CAMPAIGN_SUB_TYPES.automateSalesModuleReports]: AUTOMATE_SCHEDULED_REPORTS_MERGE_TAG,
    [CAMPAIGN_SUB_TYPES.automateLateInvoiceNotices]: AUTOMATE_LATE_INVOICE_NOTICES_MERGE_TAGS,
    [CAMPAIGN_SUB_TYPES.scheduleInvoiceReport]: SCHEDULE_REPORT_MERGE_TAGS,
    [CAMPAIGN_SUB_TYPES.schedulePrepayReport]: SCHEDULE_REPORT_MERGE_TAGS,
    [CAMPAIGN_SUB_TYPES.scheduleBatchbillingReport]: SCHEDULE_REPORT_MERGE_TAGS,
    [CAMPAIGN_SUB_TYPES.automateRevenueAndGoalsReport]: AUTOMATE_SCHEDULED_REPORTS_MERGE_TAG
  };
  if (isSN) {
    mergeTags = [...SUMMARY_NOTIFICATION_MERGE_TAGS];
  } else {
    if (isAllowedForClient) {
      if (Object.keys(automationMergeTagsMapping).includes(campaignSubType)) {
        mergeTags = [...automationMergeTagsMapping[campaignSubType]];
      } else {
        mergeTags = [
          ...MERGE_TAGS,
          ...WORKFLOW_MERGE_TAGS,
          ...SUBSCRIPTION_MERGE_TAGS,
          ...PROD_CONTACT_MERGE_TAGS,
          ...ADMATERIAL_MERGE_TAGS,
          ...AD_APPROVAL_MERGE_TAGS,
          ...ORDER_FIELDS_MERGE_TAGS,
          ...LATENOTICE_MERGE_TAGS,
          ...(isNotificationMergeTags ? REP_NOTIFICATION_MERGE_TAGS : []),
          ...PROD_NOTIFICATION_MERGE_TAGS
        ];
      }
    } else {
      mergeTags = [...MERGE_TAGS, ...WORKFLOW_MERGE_TAGS];
    }
  }
  return mergeTags;
};

export const isScrollBarBottom = (scrollTop, clientHeight, scrollHeight) => {
  const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
  return isBottom;
};

export const removeStepById = (obj = {}, stepId) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([key, value]) => key !== stepId);
  const filteredObject = Object.fromEntries(filteredEntries);
  return filteredObject;
};

export const getMatchingSubTagNames = (subtags, ids) => {
  let matchingNames = [];

  subtags.forEach((subTag) => {
    if (ids.includes(subTag.WebsiteSubTagID)) {
      matchingNames.push(subTag.SubTagName);
    }
  });

  return matchingNames;
};

export const transformData = (data) => {
  const uniqueTags = new Map();
  data.forEach((tag) => {
    tag.websiteSubTags.forEach((subTag) => {
      if (!uniqueTags.has(subTag.websiteSubTagId)) {
        uniqueTags.set(subTag.websiteSubTagId, {
          label: subTag.websiteSubTagName,
          value: subTag.websiteSubTagId
        });
      }
    });
  });

  return Array.from(uniqueTags.values());
};

export const constructSubtagsForSelect = (allWebsiteSubtags, ids, labelOnly) => {
  if (allWebsiteSubtags) {
    const subtagsData = ids
      .map((id) => {
        const { WebsiteSubTagID: value, SubTagName: label } =
          allWebsiteSubtags.find((subtag) => subtag.WebsiteSubTagID === id) || {};
        if (!value) {
          return undefined;
        }
        return { label, value };
      })
      .filter((item) => !!item);

    if (labelOnly) {
      return subtagsData.map((i) => i.label).join(", ");
    }
    return subtagsData;
  } else {
    return [];
  }
};

export const extractValueFromMultiDD = (valueStr) => {
  let values = valueStr.split("~~").slice(0, -1);
  return values.map((item) => item.split("=")[1]);
};

export const constructValuesForMultiDD = (valueArr) => {
  return valueArr.length ? valueArr.map((value) => `IE=${value}`).join("~~") + "~~" : "";
};

export const todayDateMMDDYYYY = () => moment().format("MM-DD-YYYY");
export const lastMonthDateMMDDYYYY = () => moment().subtract(1, "months").format("MM-DD-YYYY");

export const removeUndefinedValuesFromObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
};

export const extractUsedMergeTags = (htmlString) => {
  // Matches placeholders in the format <% ... %>, capturing text with alphanumeric and special characters, and optionally ignoring content after '||' (e.g., <% name || default %>).
  const regex = /<%\s*([\w\s$*#@!&.+\-]+?)(?:\|\|.*?)?\s*%>/g;
  const matches = [...htmlString.matchAll(regex)];
  return matches.map((match) => match[1].trim());
};

export const isArrayEqual = (arr1, arr2) => {
  return (
    arr1.length === arr2.length &&
    JSON.stringify(arr1.map((item) => item.toLowerCase()).sort()) ===
      JSON.stringify(arr2.map((item) => item.toLowerCase()).sort())
  );
};

export const filterInvalidMergeTags = (usedMergeTags, allMergeTags) => {
  // Filter and return invalid mergeTags
  const mergeFieldNames = allMergeTags.map((item) => {
    return item.value
      .replace(/&lt;%/g, "") // Removes the encoded opening tag &lt;% if present
      .replace(/%&gt;/g, "") // Removes the encoded closing tag %&gt; if present
      .replace("<%", "") // Removes the literal opening tag <% if present
      .replace("%>", "") // Removes the literal closing tag %> if present
      .replace(/\|\|.*$/, "") // Removes any value specified after '||' within the merge tag
      .trim();
  });
  return usedMergeTags.filter((mergeField) => mergeField !== "Email" && !mergeFieldNames.includes(mergeField));
};

export const convertHtml = (htmlString) => {
  // Converts encoded merge tag delimiters and replaces %20 with spaces in the HTML string.
  let mergeTagWrapper1 = new RegExp(`&lt;% `, "g");
  let mergeTagWrapper2 = new RegExp(` %&gt;`, "g");

  htmlString = htmlString.replace(mergeTagWrapper1, `<% `).replace(mergeTagWrapper2, ` %>`);
  // Replace %20 with space in merge tags
  let mergeTagWrapper3 = new RegExp(`<%%20`, "g");
  let mergeTagWrapper4 = new RegExp(`%20%>`, "g");
  htmlString = htmlString.replace(mergeTagWrapper3, `<% `).replace(mergeTagWrapper4, ` %>`);

  return htmlString;
};

export const extractDomain = (url) => {
  let domain;
  if (url.indexOf("://") > -1) {
    domain = url.split("/")[2];
  } else {
    domain = url.split("/")[0];
  }
  if (domain.indexOf("www.") > -1) {
    domain = domain.split("www.")[1];
  }
  domain = domain.split(":")[0];
  domain = domain.split("?")[0];
  domain = domain.replace(/\./g, ""); // Remove dots from the domain
  return domain;
};

export const convertToUSCurrencyFormat = (amount) => {
  if (!amount) return "";
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
};

export const replaceTag = (text, tagValue, recipient, tag) => {
  let replacement;
  // currentDate is a standard profile field
  if (tag.key === STANDARD_PROFILE_FIELDS.CURRENT_DATE) {
    replacement = clientSpecificDateOnly(new Date());
  } else replacement = recipient[tag.key] || tag.defaultMergeTag || "";
  return text.replace(tagValue, replacement);
};

export const hasResendSettingsChanged = (originalData, newData) => {
  if (!originalData || !newData) {
    return false;
  }
  const originalDays = originalData.days || "";
  const originalTime = originalData.time || "";
  const originalPrefix = originalData.prefixSubject || "";
  const originalSkipDays = originalData.skipDays || false;

  const newDays = newData.days || "";
  const newTime = newData.time || "";
  const newPrefix = newData.prefixSubject || "";
  const newSkipDays = newData.skipDays || false;

  return (
    newDays !== originalDays ||
    newTime !== originalTime ||
    newPrefix !== originalPrefix ||
    newSkipDays !== originalSkipDays
  );
};
// Common validation function
export const validateFieldsCampaignEditAddBeeEditor = (fields) => {
  let isInvalid = false;
  let msg_text = "";

  for (const field of fields) {
    if (field.condition) {
      msg_text += `Please fill ${field.message} <br/>`;
      isInvalid = true;
    }
  }

  if (isInvalid) {
    showAlert({ msg_type: "error", msg_text });
  }

  return isInvalid;
};

export const prepareStylesObject = function (input) {
  const EXCLUDE_FIELDS = ["required", "helpText", "errors", "popupSettings", "thankYouMsg"];
  const EXCLUDE_PROPERTIES = ["mappedTheme", "fieldTextMappedTheme"];
  if (!input) return {};
  var result = {};
  Object.keys(input).forEach(function (key) {
    if (key) {
      // Skip if field is in exclude list
      if (EXCLUDE_FIELDS.includes(key)) return;
      // Convert field name(layout -> layoutStyles) to make it compatible with landing page styles object
      const convertedKey = key === "layout" ? "layoutStyles" : key;
      // Filter out excluded properties
      const filteredStyles = Object.entries(input[key] || {}).reduce((acc, [prop, value]) => {
        if (!EXCLUDE_PROPERTIES.includes(prop)) {
          acc[prop] = value;
        }
        return acc;
      }, {});

      result[convertedKey] = {
        desktop: Object.assign({}, filteredStyles),
        mobile: {}
      };
    }
  });

  return result;
};
export const getCookieValue = (name) => {
  const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=");
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
  return cookies[name] || null;
};

export const insertDownloadReportMetrics = async (moduleName) => {
  const usageReportName = moduleName;
  const existingReport = getCookieValue(usageReportName);

  if (!existingReport) {
    try {
      await apiCall("/api/postDownloadReportMetrics", "POST", {
        key: usageReportName,
        skipTokenValidation: true
      });
    } catch (error) {
      // console.error("Error posting download report metrics:", error);
    }
  }
};

export const constructAdvSearchView = (arrayItems) => {
  return arrayItems.map((item, index) => ({
    Name: item.Name,
    ControlID: item.ControlID,
    ColumnIndex: (index % 3) + 1,
    PositionIndex: Math.floor(index / 3),
    IsShow: true
  }));
};

export const constructAdvSearchElement = ({
  Component = ADVANCED_SEARCH_COMPONENTS.MULTI_SELECT_DROPDOWN,
  Placeholder,
  FieldName,
  DisplayKey = "Display",
  ValueKey = "Value",
  SearchQueryValue,
  RequestType = "GET",
  dropdownItems,
  IsMulti = true
}) => ({
  Component,
  Placeholder,
  Propname: "",
  FieldName,
  DisplayKey,
  ValueKey,
  QueryValue: SearchQueryValue,
  IsMulti,
  AllowExclude: true,
  AllowEmptyFilter: false,
  DisableExclude: false,
  RequestType,
  ShowAll: false,
  PlusButton: false,
  AllowFilters: true,
  AllowRemove: true,
  DefaultFilterCodes: null,
  Items: dropdownItems,
  FieldCode: "",
  Tooltip: "",
  IsAndOperatorRequired: false
});

export const shouldDisableButton = (
  isResendEnabled,
  isRNCEnabled,
  isResendInfoUpdated,
  isRNCinfoUpdated,
  originalResendEnabled,
  originalRNCEnabled
) => {
  // Check if enabled states have changed
  const enabledStateChanged = isResendEnabled !== originalResendEnabled || isRNCEnabled !== originalRNCEnabled;

  // If enabled states changed, button should be enabled
  if (enabledStateChanged) {
    return false;
  }

  // If only resend is enabled, check resend info
  if (isResendEnabled && !isRNCEnabled) {
    return !isResendInfoUpdated;
  }
  // If only RNC is enabled, check RNC info
  if (isRNCEnabled && !isResendEnabled) {
    return !isRNCinfoUpdated;
  }
  // If both are enabled, enable if either has changes
  if (isResendEnabled && isRNCEnabled) {
    return !(isResendInfoUpdated || isRNCinfoUpdated);
  }
  // Disable if neither is enabled
  return true;
};

export const getUTCTimeNow = () => {
  const isoString = new Date().toISOString();
  return new Date(isoString);
};

function getNestedKeysWebhook({ path }) {
  const { allNestedKeys, isOneLevel } = prefixStringWithArray({ path });
  if (isOneLevel) return [path];
  return allNestedKeys
    .match(/(?:\w+)|(?:"[^"]*")|(?:\[\d+\])|(?:\["[^"]*"\])/g)
    .map((key) => key.replace(/[\[\]"']/g, ""));
}
const prefixStringWithArray = ({ path }) => {
  let updatedPath = "";
  let isOneLevel = false;
  if (!path.startsWith('["') && path.includes("[")) {
    updatedPath = '["' + path.replace('["', '"]["');
  } else {
    updatedPath = path;
    isOneLevel = true;
  }
  return { allNestedKeys: updatedPath, isOneLevel };
};
export function getNestedValueWebhook({ obj, path } = {}) {
  let nestedPath = getNestedKeysWebhook({ path });
  return nestedPath.reduce((acc, nextKey, index) => {
    const key = nextKey.trim();
    if (acc[nextKey] && typeof acc[nextKey] == "object" && index == nestedPath.length - 1) {
      if (Array.isArray(acc[nextKey]) && acc[nextKey].length > 0) {
        const result = acc[nextKey].some((value) => typeof value === "object");
        if (!result) {
          return acc[nextKey].join();
        }
      }
      let updatedStr = JSON.stringify(acc[nextKey]);
      return updatedStr;
    } else if ((acc && String(acc[key]).toString() == "true") || (acc && String(acc[key]).toString() == "false")) {
      let str = String(acc[key]);
      return str;
    } else if (acc && acc[key]) {
      return acc[key];
    } else if (acc && acc[key] == 0 && typeof acc[key] == "number") {
      return 0;
    } else {
      return "";
    }
  }, obj);
}

export const customerTotalDateRangeOptions = {
  currentYear: "Current year",
  nextYear: "Next year",
  pastYear: "Past year"
};

const flatten = (array) => [].concat(...array);

export const flattenGroupNames = (groupList) => {
  return flatten(
    groupList.map((group) =>
      group.groupNames.map((name) => ({
        groupNameID: name._id,
        groupNameValue: name.value,
        groupCategory: group.groupCategory
      }))
    )
  );
};

export const uniqueSelectOptionIdentifier = "-$-#-@";
export const filterGroups = (groupCategory, groupSearchText, groupsKey, groupValueKey) => {
  let searchText = groupSearchText.trim();
  if (searchText) {
    let matchingGroups = groupCategory[groupsKey].filter((group) =>
      group[groupValueKey].toLowerCase().includes(searchText.toLowerCase())
    );
    groupCategory[groupsKey] = matchingGroups;
    return matchingGroups.length > 0;
  } else {
    return true; //if group search text is empty, return all groups without filtering
  }
};

export const deepCloneObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getNestedTreeFolders = (currentFolderPath, type, treeFoldersData, isToggled, folderId, source) => {
  return apiCall(
    `/api/getTemplateFolders?folderPath=${currentFolderPath}&type=${type}&folderId=${folderId}&source=${source}`
  )
    .then((res) => {
      if (res.status === "success") {
        if (source === TEMPLATE_FOLDER_KEYS.FOLDER_TREE) {
          const foldersData = displayFolders(res.folders, treeFoldersData, isToggled, currentFolderPath);
          return foldersData;
        } else {
          return res.folders;
        }
      }
      return null;
    })
    .catch((error) => {
      console.error("Error fetching folders:", error);
      throw error;
    });
};

export const prepareFolderTreeData = (folders) => {
  return {
    getNestedFolders: {
      name: folders[0].name || HOME_FOLDER_NAME,
      path: folders[0].path || "",
      _id: folders[0]._id,
      children: folders[0].subfolders.map((folder) => ({
        name: folder.name,
        path: folder.path,
        _id: folder._id,
        __typename: "Folder"
      }))
    }
  };
};

export const foldersReducer = (folders, { type, data, togged, folderPath }) => {
  switch (type) {
    case "FOLDER_DATA_LOADED": {
      if (!folders.getNestedFolders || !folders.getNestedFolders.children.length) {
        return data;
      }
      const pathArray = folderPath.replace(/\/$/, "").split("/");
      if (pathArray.length === 1) {
        return {
          ...folders,
          getNestedFolders: {
            ...folders.getNestedFolders,
            children: data.getNestedFolders.children,
            toggled: togged
          }
        };
      }
      const updateChildren = (children, depth = 1) => {
        return children.map((child) => {
          if (child.name === pathArray[depth]) {
            if (depth === pathArray.length - 1) {
              return {
                ...child,
                toggled: togged,
                children: data.getNestedFolders.children
              };
            }
            return {
              ...child,
              children: child.children ? updateChildren(child.children, depth + 1) : []
            };
          }
          return child;
        });
      };
      return {
        ...folders,
        getNestedFolders: {
          ...folders.getNestedFolders,
          children: updateChildren(folders.getNestedFolders.children)
        }
      };
    }
    default:
      return folders;
  }
};

export const displayFolders = (folder, treeFoldersData, isToggled, currentFolderPath) => {
  const initialState = prepareFolderTreeData(folder);
  const foldersData = foldersReducer(treeFoldersData, {
    type: "FOLDER_DATA_LOADED",
    data: initialState,
    togged: isToggled,
    folderPath: currentFolderPath
  });
  return foldersData;
};

export const moveTemplate = (selectedFolder, type, templateId) => {
  return apiCall("/api/moveTemplate", "POST", {
    targetFolderId: selectedFolder,
    type: type,
    templateId: templateId
  })
    .then((res) => {
      if (res.status === "success") {
        showAlert({ msg_type: "success", msg_text: "Template moved successfully" });
      } else {
        showAlert({ msg_type: "error", msg_text: res.message });
      }
    })
    .catch((error) => {
      console.error("Error moving template:", error);

      showAlert({ msg_type: "error", msg_text: "Error moving template" });
    });
};
